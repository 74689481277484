import TransactionTableData from "./TransactionTableData";

const Table = ({ data, th, getTransactions }: any) => {
  return (
    <div className="tab-content">
      <div
        className="tab-pane fade show active"
        id="contactsListPane"
        role="tabpanel"
        aria-labelledby="contactsListTab"
      >
        <div
          className="card"
          data-list='{"valueNames": ["item-name", "item-title", "item-email", "item-phone", "item-score", "item-company"], "page": 10, "pagination": {"paginationClass": "list-pagination"}}'
          id="contactsList"
        >
          {data?.length !== 0 ? (
            <div className="table-responsive">
              <table className="table table-sm table-hover table-nowrap card-table">
                <thead>
                  <tr>
                    {th?.map((h: string, i: number) => {
                      return (
                        <th key={i}>
                          <a
                            className="list-sort text-muted"
                            data-sort={`item-${h}`}
                            href="#!"
                          >
                            {h.toUpperCase()}
                          </a>
                        </th>
                      );
                    })}
                  </tr>
                </thead>

                <TransactionTableData
                  getTransactions={getTransactions}
                  data={data}
                />
              </table>
            </div>
          ) : (
            <h3 className="py-4 text-center">No Recent Transactions'</h3>
          )}
        </div>
      </div>
    </div>
  );
};
export default Table;
