import { DashboardLayout, BaseLayout } from "@layouts/index";

import {
  getProfile,
  getProperties,
  // searchPropByKey,
} from "@services/krent.service";

import { FiltersCard, SearchCard2 } from "@shared/cards";
import { useEffect, useState } from "react";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";

import { PropertyCardList } from "../../components/lists/PropertyCardList";

import { useSearchParams } from "react-router-dom";

import { useSessionStorage } from "../../hooks";
import Pagination from "components/Pagination";

import PopularLocation from "components/PopularLocation";

import { getPageFromSessionStorage } from "@utils/helpers";
import Modal from "react-responsive-modal";
import { Breadcrumb } from "react-bootstrap";
import {
  capitalizedWord,
  extractNumber,
  // formatLinkText,
  // getDataProperties,
  getH1,
  getH2,
  reverseFormatLinkText,
} from "./helper";

import GetMeta from "./GetMeta";
import Faq from "./Faq";
import RadioSearchNav from "@shared/cards/RadioSearchNav";
import {
  AbujaArticles,
  CommercialArticle,
  HouseArticles,
  IkoyiArticles,
  LagosArticles,
  LandArticle,
  LekkiArticles,
  RentApartmentArticle,
  RentArticles,
  SalesApartmentArticle,
  SalesArticles,
  ShortletArticles,
} from "./Articles";

type ParamsType = {
  [key: string]: string;
};

export const PropertiesPage = () => {
  const [user] = useSessionStorage("user", {});
  const [userPendingTransactions, setUserPendingTransactions] = useState<any>();

  const [queryParams] = useSearchParams();

  const location: any = useLocation();

  const {
    type,
    location: paramLocation,
    kind,
    bedroom: paramBedrooms,
  }: any = useParams();

  // const params = new URLSearchParams(location.search);

  // const intent = params.get('intent');

  const [loading, setLoading] = useState<boolean>(false);

  const [page, setPage] = useState<any>(parseInt(getPageFromSessionStorage()));

  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [inprogressTransactions, setInprogressTransactions] = useState(0);
  const [pendingInspectionTransactions, setPendingInspectionTransactions] =
    useState(0);
  const [pendingPaymentTransactions, setPendingPaymentTransactions] =
    useState(0);
  const limit: any = 21;
  const [data, setData] = useState<any>({
    properties: [],
    current_page: 1,
    page_count: 0,
    total: 0,
    numOfPages: 1,
  });
  // const [cities, setCities] = useState<string[]>([]);
  // const [bedrooms, setBedrooms] = useState<string[]>([]);
  // const [kinds, setKinds] = useState<string[]>([]);

  // Fetch properties function
  const fetchProperties = (pageNumber = 1) => {
    setLoading(true);

    let intent = type;
    if (type === "rent") {
      intent = "long lease";
    }

    const params: ParamsType = {};

    if (paramLocation) {
      params["location.city"] = reverseFormatLinkText(
        capitalizedWord(paramLocation)
      );
    }
    if (paramBedrooms) {
      params["bedrooms"] = extractNumber(paramBedrooms);
    }
    if (kind) {
      if (kind === "house") {
        params["kind"] = "houses";
      }
      if (kind === "apartment") {
        params["kind.title"] = "Apartment";
      }
      if (kind === "commercial") {
        params["kind"] = "commercial";
      }
      if (kind === "land") {
        params["kind.title"] = "Land";
      }
    }
    queryParams.forEach((value, key) => {
      params[key] = value;
    });

    // Update the browser's URL with only the page number if it is greater than 1
    let newUrl = `${window.location.pathname}`;
    if (pageNumber > 1) {
      const urlParams = new URLSearchParams();
      urlParams.set("page", pageNumber.toString());
      newUrl += `?${urlParams.toString()}`;
    }

    window.history.pushState(null, "", newUrl);

    let abortController = new AbortController();

    getProperties(
      { ...params, intent, limit, page: pageNumber.toString() },
      abortController.signal
    )
      .then((res: any) => {
        const { page_count, data, current_page, total } = res;
        setData({
          properties: data,
          page_count,
          total,
          current_page,
          numOfPages: Math.ceil(total / limit),
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      abortController.abort();
    };
  };

  // const locationComponents: any = {
  //   lagos: LagosArticles,
  //   ikoyi: LagosArticles,
  //   lekki: LagosArticles,
  //   abuja: AbujaArticles,
  // };

  // const typeComponents: any = {
  //   sale: SalesArticles,
  //   rent: RentArticles,
  //   shortlet: ShortletArticles,
  //   house: HouseArticles,
  // };

  // const ComponentToRender: any =
  //   locationComponents[paramLocation] || typeComponents[type] || null;

  // // Fecth properties function
  // const fetchProperties = () => {
  //   setLoading(true);

  //   let intent = type;
  //   if (type === 'rent') {
  //     intent = 'long lease';
  //   }

  //   const params: {[x: string]: string} = {};

  //   if (paramLocation) {
  //     params['location.city'] = reverseFormatLinkText(
  //       capitalizedWord(paramLocation)
  //     );
  //   }
  //   if (paramBedrooms) {
  //     params['bedrooms'] = extractNumber(paramBedrooms);
  //   }
  //   if (kind) {
  //     if (kind === 'house') {
  //       params['kind'] = 'houses';
  //     }
  //     if (kind === 'apartment') {
  //       params['kind.title'] = 'Apartment';
  //     }
  //     if (kind === 'commercial') {
  //       params['kind'] = 'commercial';
  //     }
  //     if (kind === 'land') {
  //       params['kind.title'] = 'Land';
  //     }
  //   }
  //   // if (kind) {
  //   //   params['kind.title'] = capitalizedWord(kind);
  //   // }
  //   queryParams.forEach((value, key: string) => {
  //     params[key] = value;
  //   });

  //   let abortController: AbortController = new AbortController();

  //   getProperties({...params, intent, limit, page}, abortController.signal)
  //     .then((res: any) => {
  //       const {page_count, data, current_page, total} = res;
  //       setData({
  //         properties: data,
  //         page_count,
  //         total,
  //         current_page,
  //         numOfPages: Math.ceil(total / limit),
  //       });
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });

  //   return () => {
  //     abortController.abort();
  //   };
  // };

  useEffect(() => {
    let abortController: AbortController = new AbortController();
    getProfile(abortController.signal)
      .then((re: any) => {
        setUserPendingTransactions(re?.data);
      })
      .catch((error: any) => {
        console.log("Error: ", error);
      });
  }, []);

  useEffect(() => {
    if (user.role !== "user") return;
    if (!userPendingTransactions) return;

    const { pendingTransactions } = userPendingTransactions;
    if (!pendingTransactions) return;

    const inprogress = pendingTransactions.filter(
      (item: any) => item.status === "progress"
    );
    const pendingInspection = pendingTransactions.filter(
      (item: any) => item.status === "awaiting-inspection"
    );
    const pendingPayment = pendingTransactions.filter(
      (item: any) => item.status === "customer-accepted"
    );

    const inprogressCount = inprogress.length > 0 ? inprogress[0].count : 0;
    const pendingInspectionCount =
      pendingInspection.length > 0 ? pendingInspection[0].count : 0;
    const pendingPaymentCount =
      pendingPayment.length > 0 ? pendingPayment[0].count : 0;

    setInprogressTransactions(inprogressCount);
    setPendingInspectionTransactions(pendingInspectionCount);
    setPendingPaymentTransactions(pendingPaymentCount);

    if (
      inprogressCount > 0 ||
      pendingInspectionCount > 0 ||
      pendingPaymentCount > 0
    ) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [userPendingTransactions]);

  // if no global search this takes effect
  useEffect(() => {
    // Update the 'intent' state if the query parameter changes
    if (!location.state?.searchResult || location.state === null) {
      fetchProperties(page);
    }
  }, [queryParams, location.pathname, page]);

  useEffect(() => {
    // this set the page back to 1 if a new intent is called
    if (location?.state?.page) {
      setPage(location?.state?.page);
    }
  }, [type, paramLocation, queryParams]);

  // This is to fetch list of cities, bedrooms , and kind seperately
  // useEffect(() => {
  //   let abortController: AbortController = new AbortController();
  //   let int = type;
  //   if (type === 'rent') {
  //     int = 'long lease';
  //   }

  //   getProperties({intent: int, limit: '100000'}, abortController.signal)
  //     .then((res: any) => {
  //       const {data} = res;

  //       const {cities, bedrooms, kinds}: any = getDataProperties(data);
  //       setCities(cities);
  //       setBedrooms(bedrooms);
  //       setKinds(kinds);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });

  //   return () => abortController.abort();
  // }, [type, paramLocation, queryParams]);

  return user && user?._id ? (
    <DashboardLayout>
      {/* <Helmet>
        <title>Find House, Flats & Land For Sale in Nigeria | Krent</title>
        <meta
          name='description'
          content='Find Houses, Flats & Land for Sale in Nigeria on Krent. Search by price, location, property size, number of bedrooms, and more for a tailored property search experience.'
        />
      </Helmet> */}
      <GetMeta
        type={type}
        bedroom={paramBedrooms}
        kind={kind}
        location={paramLocation}
        total={data?.total}
      />
      <section className="container mt-5 mb-5">
        <h5 className="fw-bolder mb-3 pt-3">Filter Properties</h5>
        <div className="bg-white col-12   rounded">
          {/* <SearchNav /> */}
          <RadioSearchNav type={type} setPage={setPage} />
          <SearchCard2
            extraClass="rounded-top-left-0 rounded-top-right-0"
            sizeClass="col-md-6 col-8"
            page={page}
            setPage={setPage}
          />
        </div>

        {filtersLoaded && (
          <Link
            className="btn btn-link fw-bold px-0 text-danger ml-1 no-decoration"
            to={"/explore"}
            onClick={() => setFiltersLoaded(false)}
          >
            Clear Filters
          </Link>
        )}
      </section>
      <section className="container">
        <Breadcrumb className="mt-2">
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>

          <Breadcrumb.Item
            active={!kind && !paramLocation ? true : false}
            href={`/property-for-${type}`}
          >{`Property for ${type}`}</Breadcrumb.Item>
          {kind && (
            <Breadcrumb.Item
              active={!paramLocation ? true : false}
              href={`/property-for-${type}/${kind}`}
            >{`${kind}`}</Breadcrumb.Item>
          )}
          {paramLocation && (
            <Breadcrumb.Item active>{`${paramLocation}`}</Breadcrumb.Item>
          )}
        </Breadcrumb>
      </section>
      <section className="container">
        {location.state?.searchResult ? (
          <div>
            <i className="fa fa-remove text-danger"></i>
            <Link
              className=" btn-link  px-0 text-danger ml-1 d-inline-block mt-1  no-decoration"
              to={
                window.location.pathname.includes("properties")
                  ? "/explore"
                  : "/properties"
              }
            >
              Clear search
            </Link>
          </div>
        ) : (
          ""
        )}
        {loading ? (
          <p>Loading....</p>
        ) : data?.properties?.length === 0 ? (
          <h4>
            We couldn't find any matches for your search. Please consider trying
            different keywords.
          </h4>
        ) : (
          <div>
            <h1 className="fs-20 mt-3" style={{ lineHeight: "1.5" }}>
              {" "}
              {getH1(type, paramLocation, paramBedrooms, kind)}{" "}
            </h1>
            <h2
              className="fs-14 mb-3 p-3 col-md-8 d-none d-md-block"
              style={{
                lineHeight: "2",
                fontWeight: "normal",
                backgroundColor: "#EFF1EF",
                color: "#171D1B",
                borderRadius: "8px",
              }}
            >
              {getH2(
                type,
                paramLocation ? paramLocation : undefined,
                data?.total,
                paramBedrooms,
                kind
              )}
            </h2>
            {/* <div
              className='p-3 mb-3 col-md-8'
              style={{backgroundColor: '#ECF2EF', borderRadius: '8px'}}
            >
              <ul
                className='d-flex flex-wrap gap-3'
                style={{
                  boxSizing: 'border-box',
                  width: '100%',
                  paddingLeft: '0',
                }}
              >
                {bedrooms.map((bedroom: any, index: number) => {
                  const formatLink = formatLinkText(`${bedroom}bedroom`);

                  return (
                    <li
                      key={index}
                      className='list-unstyled'
                      style={{
                        // borderRight: '2px solid #6F7976',
                        paddingRight: '8px',
                      }}
                      onClick={() => setPage(1)}
                    >
                      <Link
                        to={`/property-for-${type}/bedroom/${formatLink}`}
                        style={{color: '#00201B'}}
                      >
                        {bedroom}
                        {bedroom === 1 ? 'bedroom' : 'bedrooms'}
                      </Link>
                    </li>
                  );
                })}{' '}
              </ul>

              <ul
                className='d-flex flex-wrap gap-3 '
                style={{
                  boxSizing: 'border-box',
                  width: '100%',
                  paddingLeft: '0',
                }}
              >
                {kinds.map((kind: any, index: number) => {
                  return (
                    <li
                      key={index}
                      className='list-unstyled'
                      style={{
                        // borderRight: '2px solid #6F7976',
                        paddingRight: '8px',
                      }}
                      onClick={() => setPage(1)}
                    >
                      <Link
                        to={`/property-for-${type}/kind/${kind.toLowerCase()}`}
                        style={{color: '#00201B'}}
                      >
                        {kind}
                      </Link>
                    </li>
                  );
                })}{' '}
              </ul>
              <ul
                className='d-flex flex-wrap gap-3'
                style={{
                  boxSizing: 'border-box',
                  width: '100%',
                  paddingLeft: '0',
                }}
              >
                {cities.map((city: any, index: number) => {
                  return (
                    <li
                      key={index}
                      className='list-unstyled'
                      style={
                        {
                          // borderRight: '1px solid #6F7976',
                          // paddingRight: '8px',
                        }
                      }
                      onClick={() => setPage(1)}
                    >
                      <Link
                        to={`/property-for-${type}/${formatLinkText(
                          city.toLowerCase()
                        )}`}
                        style={{color: '#00201B'}}
                      >
                        {city}
                      </Link>{' '}
                    </li>
                  );
                })}{' '}
              </ul>
            </div> */}
            <PropertyCardList properties={data.properties} loading={loading} />
          </div>
        )}
        {/* {loadingMore && <p>Loading...</p>} */}

        <Pagination
          page={page}
          numOfPages={data.numOfPages}
          setPage={setPage}
          // changePage={changePage}
        />
        {/* {ComponentToRender ? <ComponentToRender /> : ''} */}
        {paramLocation === "lagos" ? (
          <LagosArticles />
        ) : paramLocation === "abuja" ? (
          <AbujaArticles />
        ) : kind === "house" && type === "sale" ? (
          <HouseArticles />
        ) : kind === "land" ? (
          <LandArticle />
        ) : kind === "house" && type === "rent" ? (
          ""
        ) : type === "sale" && !paramLocation && !kind && !paramBedrooms ? (
          <SalesArticles />
        ) : type === "sale" && !paramLocation && kind && !paramBedrooms ? (
          <SalesArticles />
        ) : type === "rent" && !paramLocation && !kind && !paramBedrooms ? (
          <RentArticles />
        ) : type === "rent" && !paramLocation && kind && !paramBedrooms ? (
          <RentArticles />
        ) : type === "shortlet" && !paramLocation && !kind && !paramBedrooms ? (
          <ShortletArticles />
        ) : (
          ""
        )}

        {(paramLocation || kind || type) && !paramBedrooms && (
          <Faq
            total={data?.total}
            properties={data?.properties}
            type={type}
            kind={kind}
            location={paramLocation}
          />
        )}
      </section>

      <div
        className="offcanvas offcanvas-end"
        tabIndex={-1}
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title fw-bolder" id="offcanvasExampleLabel">
            Filters
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <FiltersCard
          page={page}
          limit={limit}
          setPage={setPage}
          setFiltersLoaded={setFiltersLoaded}
        />
      </div>

      <Modal
        center
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        classNames={{
          modalAnimationIn: "customEnterModalAnimation",
          modalAnimationOut: "customLeaveModalAnimation",
          modal: "customModal",
        }}
        animationDuration={500}
        aria-labelledby="summary-title"
        aria-describedby="summary-description"
      >
        <div className="container pb-3 mt-5 ">
          <div
            className=" p-3 d-flex align-items-center gap-2 mb-2"
            style={{ background: "#FEFCE8", color: "#854D0E" }}
          >
            <i
              className="fa fa-triangle-exclamation "
              style={{ color: "#EFDB11" }}
            ></i>
            <p className="fw-bold fs-12">Attention needed</p>
          </div>
          {inprogressTransactions > 0 && (
            <p className="fs-14">
              You have{" "}
              <span className="fw-bold ">{inprogressTransactions}</span> open{" "}
              {inprogressTransactions > 1 ? "transactions" : "transaction"}
            </p>
          )}
          {pendingPaymentTransactions > 0 && (
            <p className="fs-14">
              You have{" "}
              <span className="fw-bold ">{pendingPaymentTransactions}</span>{" "}
              {pendingPaymentTransactions > 1 ? "transactions" : "transaction"}{" "}
              pending payment.
            </p>
          )}
          {pendingInspectionTransactions > 0 && (
            <p className="fs-14">
              You have{" "}
              <span className="fw-bold ">{pendingInspectionTransactions}</span>{" "}
              {pendingInspectionTransactions > 1 ? "properties" : "property"}{" "}
              awaiting inspection.
            </p>
          )}
          <p className="fw-bold fs-13 text-secondary">
            Act now to complete or close the transaction to allow future
            property bookings.
          </p>
          <div className="d-flex">
            <Link
              to="/my-bookings"
              // data-bs-dismiss='modal'
              className="btn bg-primary rounded-pill px-md-5  py-2 text-white fw-bold mt-3"
              // onClick={handleNavigateToInvoice}
            >
              Take Action
            </Link>
            <button
              data-bs-dismiss="modal"
              className="btn  rounded-pill px-md-5 py-2 text-secondary fs-13 mt-3 ml-3"
              onClick={() => {
                setShowModal(false);
              }}
            >
              Do this later
            </button>
          </div>
        </div>
      </Modal>
    </DashboardLayout>
  ) : (
    <BaseLayout setPage={setPage}>
      <GetMeta
        type={type}
        bedroom={paramBedrooms}
        kind={kind}
        location={paramLocation}
        total={data?.total}
      />
      <section className="container mt-5 ">
        <h5 className="fw-bolder mb-3">Filter Properties</h5>

        <div className="bg-white col-12   rounded">
          {/* <SearchNav handleSearchTab={handleSearchTab} /> */}
          <div className="d-lg-none">
            <RadioSearchNav type={type} setPage={setPage} />
          </div>
          <SearchCard2
            extraClass="rounded-top-left-0 rounded-top-right-0"
            sizeClass=" col-md-6 col-8"
            page={page}
            setPage={setPage}
          />
          {filtersLoaded && (
            <NavLink
              to={"/explore/"}
              className="btn btn-link text-danger fs-10 px-3 text-decoration-none"
              onClick={() => setFiltersLoaded(false)}
            >
              Clear Filters
            </NavLink>
          )}
        </div>
      </section>
      <section className="container">
        <Breadcrumb className="mt-2">
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>

          <Breadcrumb.Item
            active={!kind && !paramLocation ? true : false}
            href={`/property-for-${type}`}
          >{`Property for ${type}`}</Breadcrumb.Item>
          {kind && (
            <Breadcrumb.Item
              active={!paramLocation ? true : false}
              href={`/property-for-${type}/${kind}`}
            >{`${kind}`}</Breadcrumb.Item>
          )}
          {paramLocation && (
            <Breadcrumb.Item active>{`${paramLocation}`}</Breadcrumb.Item>
          )}
        </Breadcrumb>
      </section>
      <section className="container">
        {location?.state ? (
          <div>
            {/* <i className='fa fa-remove text-danger'></i> */}
            {/* <Link
              className=' btn-link  px-0 text-danger ml-1 d-inline-block  no-decoration'
              to={
                window.location.pathname.includes('properties')
                  ? '/explore'
                  : '/properties'
              }
            >
              Clear search
            </Link> */}
          </div>
        ) : (
          ""
        )}
        {/* <div>
          <h1 className='fs-20 lh-34 mb-3 mt-2'>
            {intent === 'shortlet'
              ? `Shortlet Property in Nigeria`
              : intent === 'long lease'
              ? `Property for Rent in Nigeria`
              : intent === 'sale'
              ? `Property for Sale in Nigeria`
              : ''}
          </h1>
        </div> */}
        {loading ? (
          <p>Loading....</p>
        ) : data.properties.length === 0 ? (
          <h4>
            We couldn't find any matches for your search. Please consider trying
            different keywords.
          </h4>
        ) : (
          <div>
            <h1 className="fs-20 mt-3" style={{ lineHeight: "1.5" }}>
              {" "}
              {getH1(type, paramLocation, paramBedrooms, kind)}{" "}
            </h1>
            <h2
              className="fs-14 mb-3 p-3 col-md-8 d-none d-md-block"
              style={{
                lineHeight: "2",
                fontWeight: "normal",
                backgroundColor: "#EFF1EF",
                color: "#171D1B",
                borderRadius: "8px",
              }}
            >
              {getH2(
                type,
                paramLocation ? paramLocation : undefined,
                data?.total,
                paramBedrooms,
                kind
              )}
            </h2>

            {/* <div
              className='p-3 mb-3 col-md-8'
              style={{backgroundColor: '#ECF2EF', borderRadius: '8px'}}
            >
              <ul
                className='d-flex flex-wrap gap-3'
                style={{
                  boxSizing: 'border-box',
                  width: '100%',
                  paddingLeft: '0',
                }}
              >
                {bedrooms.map((bedroom: any, index: number) => {
                  const formatLink = formatLinkText(`${bedroom}bedroom`);

                  return (
                    <li
                      key={index}
                      className='list-unstyled'
                      style={{
                        // borderRight: '2px solid #6F7976',
                        paddingRight: '8px',
                      }}
                      onClick={() => setPage(1)}
                    >
                      <Link
                        to={`/property-for-${type}/bedroom/${formatLink}`}
                        style={{color: '#00201B'}}
                      >
                        {bedroom}
                        {bedroom === 1 ? 'bedroom' : 'bedrooms'}
                      </Link>
                    </li>
                  );
                })}{' '}
              </ul>

              <ul
                className='d-flex flex-wrap gap-3 '
                style={{
                  boxSizing: 'border-box',
                  width: '100%',
                  paddingLeft: '0',
                }}
              >
                {kinds.map((kind: any, index: number) => {
                  return (
                    <li
                      key={index}
                      className='list-unstyled'
                      style={{
                        // borderRight: '2px solid #6F7976',
                        paddingRight: '8px',
                      }}
                      onClick={() => setPage(1)}
                    >
                      <Link
                        to={`/property-for-${type}/kind/${kind.toLowerCase()}`}
                        style={{color: '#00201B'}}
                      >
                        {kind}
                      </Link>
                    </li>
                  );
                })}{' '}
              </ul>
              <ul
                className='d-flex flex-wrap gap-3'
                style={{
                  boxSizing: 'border-box',
                  width: '100%',
                  paddingLeft: '0',
                }}
              >
                {cities.map((city: any, index: number) => {
                  return (
                    <li
                      key={index}
                      className='list-unstyled'
                      style={
                        {
                          // borderRight: '1px solid #6F7976',
                          // paddingRight: '8px',
                        }
                      }
                      onClick={() => setPage(1)}
                    >
                      <Link
                        to={`/property-for-${type}/${formatLinkText(
                          city.toLowerCase()
                        )}`}
                        style={{color: '#00201B'}}
                      >
                        {city}
                      </Link>{' '}
                    </li>
                  );
                })}{' '}
              </ul>
            </div> */}
            <PropertyCardList properties={data.properties} loading={loading} />
          </div>
        )}

        <Pagination
          page={page}
          numOfPages={data.numOfPages}
          setPage={setPage}
        />
        {/* {ComponentToRender ? <ComponentToRender /> : ''} */}
        {paramLocation === "lagos" ? (
          <LagosArticles />
        ) : paramLocation === "abuja" ? (
          <AbujaArticles />
        ) : paramLocation === "lekki" ? (
          <LekkiArticles />
        ) : paramLocation === "ikoyi" ? (
          <IkoyiArticles />
        ) : kind === "house" && type === "sale" ? (
          <HouseArticles />
        ) : kind === "land" ? (
          <LandArticle />
        ) : type === "rent" && kind === "apartment" ? (
          <RentApartmentArticle />
        ) : type === "sale" && kind === "apartment" ? (
          <SalesApartmentArticle />
        ) : type === "sale" && kind === "commercial" ? (
          <CommercialArticle />
        ) : kind === "house" && type === "rent" ? (
          ""
        ) : type === "sale" && !paramLocation && !kind && !paramBedrooms ? (
          <SalesArticles />
        ) : type === "sale" && !paramLocation && kind && !paramBedrooms ? (
          <SalesArticles />
        ) : type === "rent" && !paramLocation && !kind && !paramBedrooms ? (
          <RentArticles />
        ) : type === "rent" && !paramLocation && kind && !paramBedrooms ? (
          <RentArticles />
        ) : type === "shortlet" && !paramLocation && !kind && !paramBedrooms ? (
          <ShortletArticles />
        ) : (
          ""
        )}
        {(paramLocation || kind || type) && !paramBedrooms && (
          <Faq
            total={data?.total}
            properties={data?.properties}
            type={type}
            kind={kind}
            location={paramLocation}
          />
        )}
      </section>

      <section className="container py-5 px-0 px-md-3">
        <PopularLocation />
      </section>

      <section className="pb-5">
        <div className="container text-center">
          <h4 className="fw-bolder">
            You're one step away from an amazing experience
          </h4>
          <div className="d-grid gap-3 d-md-block my-4">
            <Link
              to="/sign-in/"
              className="btn btn-custom-primary py-3 px-4 rounded-pill me-2 fs-14 fw-600"
            >
              List A Property
            </Link>
            <Link
              to="/explore/"
              className="btn btn-white border-dark py-3 px-3 rounded-pill fs-14 fw-600"
            >
              {" "}
              Browse Properties
            </Link>
          </div>
        </div>
      </section>

      <div
        className="offcanvas offcanvas-end"
        tabIndex={-1}
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header px-4">
          <h5 className="offcanvas-title fw-bolder" id="offcanvasExampleLabel">
            Filters
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>

        <FiltersCard
          page={page}
          limit={limit}
          setPage={setPage}
          setFiltersLoaded={setFiltersLoaded}
        />
      </div>
    </BaseLayout>
  );
};
