import { DashboardLayout } from "@layouts/index";

import notification from "../../assets/images/notifications.png";
import { useEffect, useState } from "react";
import {
  // cancelTransaction,
  getPropertyTransactions,
  // getWalletTransactions,
} from "@services/krent.service";
import { useSessionStorage } from "../../hooks";
import Table from "@shared/Table/Table";
import ktoast from "@services/toast.service";

// import {useNavigate} from 'react-router-dom';
// import { TransactionCard } from '@shared/cards';

export const Invoice = () => {
  const [user] = useSessionStorage("user", {});
  const [loading, setLoading] = useState<boolean>(false);
  const [transactions, setTransactions] = useState<any[]>([]);
  const [pendingPayments, setPendingPayments] = useState<number>(0);
  const [completedPayments, setCompletedPayments] = useState<number>(0);

  const handleGetPropertyTransactions = () => {
    setLoading(true);
    let abortController: AbortController = new AbortController();
    getPropertyTransactions(user?.id, abortController.signal)
      .then((res: any) => {
        setTransactions(res.data);
        setLoading(false);

        setPendingPayments(
          res?.data
            ?.filter((c: any) => c.status === "customer-accepted")
            .reduce((a: number, obj: any) => a + parseInt(obj?.bill?.total), 0)
        );
        setCompletedPayments(
          res?.data
            ?.filter((c: any) => c.status === "completed")
            .reduce((a: number, obj: any) => a + parseInt(obj?.bill?.total), 0)
        );
      })
      .catch((err) => {
        setLoading(false);
        ktoast.error(err?.errors[0]?.message);
        // Failed to load transactions
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      abortController.abort();
    };
  };

  useEffect(() => {
    handleGetPropertyTransactions();
  }, [user]);

  // useEffect(() => {
  //   setLoading(true);
  //   const {wallet: walletID} = user;
  //   let abortController = new AbortController();
  //   if (walletID) {
  //     getWalletTransactions(walletID, abortController.signal)
  //       .then(() => {
  //         // console.log(res);
  //         // setWallet(res.data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   } else {
  //     // setModalOpen(true);
  //   }
  //   return () => {
  //     abortController.abort();
  //     // setModalOpen(false);
  //   };
  // }, [user]);

  return (
    <DashboardLayout>
      <div className="row mb-3">
        <h3 className="fw-bold mb-1">Invoice</h3>
        <p className="text-secondary fs-14">Manage your invoices on krent</p>
      </div>

      <div className="row mb-5">
        <div className="col-12 col-md-4 mb-3 mb-md-0">
          <div className="card border-0 border-round px-4 py-5 shadow-sm h-max">
            <h6 className="fs-14 mt-1">
              Pending Payments{" "}
              <i
                className="iconly-Info-Circle icli"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Tooltip on top"
              ></i>
            </h6>
            <h3 className="fw-bold">NGN {pendingPayments?.toLocaleString()}</h3>
          </div>
        </div>
        <div className="col-12 col-md-4 mb-3 mb-md-0">
          <div className="card border-0 border-round px-4 py-5 shadow-sm h-max">
            <h6 className="fs-14 mt-1">
              Completed Payments <i className="iconly-Info-Circle icli"></i>
            </h6>
            <h3 className="fw-bold">
              NGN {completedPayments?.toLocaleString()}
            </h3>
          </div>
        </div>
        <div className="col-12 col-md-4 mb-3 mb-md-0">
          <div className="card border-0 border-round px-4 py-5 shadow-sm h-max">
            <h6 className="fs-14 mt-1">
              Locked Payments <i className="iconly-Info-Circle icli"></i>
            </h6>
            <h3 className="fw-bold">NGN 0.00</h3>
          </div>
        </div>
      </div>

      <div className="row mt-5 mt-md-4">
        <div className="col-12 mb-5 mb-md-0">
          <h6 className="fw-bold fs-16 mb-3">Transactions</h6>
          {loading ? (
            <p>Loading</p>
          ) : transactions?.length > 0 ? (
            <Table
              data={transactions}
              th={[
                "Property",
                "owner",
                "intent",
                "Arrival Date",
                "Departure Date",

                "inspected",
                // 'inspection date',
                "Updated date",
                "Creation date",
                "status",
                "Actions",
              ]}
              transactions
              getTransactions={() => handleGetPropertyTransactions()}
            />
          ) : (
            // <div
            //   className='card border-0 shadow-sm border-round p-3 overflow-auto scrollbar-custom'
            //   style={{ height: 380 }}
            // >
            //   {transactions
            //     ?.sort(function compare(a, b) {
            //       let dateA: any = new Date(a.initiatedAt);
            //       let dateB: any = new Date(b.initiatedAt);

            //       return dateB - dateA;
            //     })
            //     .map((transaction, key) => (
            //       <TransactionCard
            //         key={key}
            //         description={`${transaction?.status} to ${transaction?.destination}`}
            //         property={transaction?.otherDetails ?? ''}
            //         time={transaction?.initiatedAt}
            //         type={transaction?.type}
            //         value={transaction.amount}
            //       />
            //     ))}
            // </div>
            <div className="card border-0 border-round p-5 text-center d-flex align-items-center">
              <div>
                <h6 className="fs-18 mt-1 fw-bold mb-4">No transactions yet</h6>
                <img src={notification} alt="No transactions" width={200} />
                <p className="fs-14 light-text">
                  Any transactions you make would show up here. You don’t have
                  any right now
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};
