import { BaseApiResponse } from "@interfaces/app.interface";
import { NotificationsInterfaceResponse } from "@interfaces/notifications.interface";
import { PropertyListResponse } from "@interfaces/properties.interface";
import { DashboardLayout } from "@layouts/index";
import {
  getAgentKycStatus,
  getListings,
  getPersonalKycStatus,
  // getUnreadUserNotifications,
  getUserNotifications,
  // markReadNotification,
} from "@services/krent.service";
import { NotificationCard, OverviewCard } from "@shared/cards";
import { useEffect, useState } from "react";
import emptyNotifications from "../../assets/images/notifications.png";
import { useSessionStorage } from "../../hooks";
import { useNavigate } from "react-router-dom";
import KycAlert from "components/KycAlert";
// import NotificationBellDropdown from 'components/Notification';
// import {KycData} from '@interfaces/kyc.interface';

export const Overview = () => {
  const [user] = useSessionStorage("user", {});
  const [listings, setListings] = useState<PropertyListResponse[]>([]);

  const [renderKycAlert, setRenderKycAlert] = useState(false);
  const [kycStatus, setKycStatus] = useState("");
  const [notifications, setNotifications] = useState<
    NotificationsInterfaceResponse[]
  >([]);
  // const [notificationsCount, setNotificationsCount] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchKyc = async () => {
      let render = false; // Set a default value for render
      const resp = await getPersonalKycStatus();

      // Handling personal KYC status
      resp === undefined && setKycStatus("undefined");

      if (resp?.status === "APPROVED") {
        render = false; // No need to show alert if approved
      } else if (resp?.status === "REJECTED") {
        render = true;
        setKycStatus(`Your KYC was rejected: ${resp?.rejectionReason}`);
      } else if (resp?.status === "PENDING") {
        render = true;
        setKycStatus("Your KYC is pending approval");
      }

      // Handling agent KYC status if the user is an agent
      if (user.role === "agent") {
        const agentResp = await getAgentKycStatus();

        if (agentResp?.status === "APPROVED") {
          render = render || false; // Don't override if render is already true
        } else if (agentResp?.status === "REJECTED") {
          render = true;
          setKycStatus(`Your KYC was rejected: ${agentResp?.rejectionReason}`);
        } else if (agentResp?.status === "PENDING") {
          render = true;
          setKycStatus("Your KYC is pending approval");
        }
      }

      // Set the render state for KYC alert
      setRenderKycAlert(render);
    };

    fetchKyc();
  }, [user]);
  useEffect(() => {
    // console.log('Requesting...');
    let abortController = new AbortController();

    getListings(user?._id, {}, abortController.signal)
      .then((res: any) => {
        // console.log(res);
        setListings(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      if (!abortController.signal.aborted) abortController.abort();
    };
  }, [user]);

  useEffect(() => {
    let abortController = new AbortController();
    getUserNotifications(user?._id, abortController.signal)
      .then((res: BaseApiResponse<NotificationsInterfaceResponse[]>) => {
        setNotifications(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // getUnreadUserNotifications(user?._id, abortController.signal)
    //   .then((res: any) => {
    //     setNotificationsCount(res.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, [user]);

  // const handleMarkReadNotification = (id: string) => {
  //   let abortController = new AbortController();
  //   markReadNotification(id, abortController.signal)
  //     .then(() => {
  //       getUnreadUserNotifications(user?._id, abortController.signal)
  //         .then((res: any) => {
  //           setNotificationsCount(res.data);
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });

  //       navigate('/requests');
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  return (
    <DashboardLayout>
      <div className="row mb-3">
        <div className="col-8">
          <div className="d-flex  align-items-center">
            <h3 className="fw-bold mb-1 mr-3 ">Overview</h3>
            {/* <NotificationBellDropdown
              count={notificationsCount}
              notifications={notifications}
              markRead={handleMarkReadNotification}
            /> */}
          </div>
          <p className="text-secondary fs-14">
            Welcome back,{" "}
            {user?.name ||
              user?.prefferedName ||
              `${user?.firstname} ${user?.lastname}`}
          </p>
        </div>
        <div className="col-4">
          <div className="d-flex flex-column justify-content-end w-100">
            <button
              className="btn btn-primary"
              onClick={() => navigate("/listings")}
            >
              Create Listing
            </button>
          </div>
        </div>
      </div>

      <div className="row mb-5">
        <div className="col-12 col-md-3 mb-3 mb-md-0">
          <OverviewCard
            title="Active Shortlet Listings"
            count={listings?.filter((v) => v.intent === "shortlet").length}
            value={0}
          />
        </div>
        <div className="col-12 col-md-3 mb-3 mb-md-0">
          <OverviewCard
            title="Active Rental Listings"
            count={listings?.filter((v) => v.intent === "long lease").length}
            value={0}
          />
        </div>
        <div className="col-12 col-md-3 mb-3 mb-md-0">
          <OverviewCard
            title="Active Sales Listings"
            count={listings?.filter((v) => v.intent === "sale").length}
            value={0}
          />
        </div>
        <div className="col-12 col-md-3 mb-3 mb-md-0">
          <OverviewCard
            title="Listings Published"
            count={listings?.filter((v) => v.published === true).length}
            // value={0}
          />
        </div>
      </div>
      {kycStatus === "undefined" ? (
        <div className="my-4">
          <div
            className="flex justify-between p-3"
            style={{
              backgroundColor: "#FFDFDC",
              borderLeft: "4px solid #FF0000",
            }}
          >
            <div
              className="flex gap-3 items-center"
              style={{ color: "#FF0000" }}
            >
              <i className="fa-regular fa-user fa-2xl"></i>
              <div>
                <h6 className="m-0">Please complete your KYC.</h6>
                <p className="text-light-black">
                  Complete your KYC Process to verify your identity
                </p>
              </div>
            </div>
            <button
              className="bg-primary rounded-10 text-white fw-bold px-4"
              onClick={() => navigate("/settings?tab=kyc")}
            >
              Add KYC
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="col card border-0 shadow-sm">
        {/* {user.role !== 'agent' && kyc?.status !== 'APPROVED' && <KycAlert />} */}
        {renderKycAlert && <KycAlert status={kycStatus} />}
        <div className="card-header bg-dark py-3">
          <div className="d-flex justify-content-between">
            <span className="text-white">Notification Center</span>
            {/* <span className='text-white'>
							<a href="#!" className='text-white text-link'>View All <i className='iconly-Arrow-Right-2 icli fs-14'></i></a>
						</span> */}
          </div>
        </div>
        <div
          className="card-body border-0-last overflow-auto scrollbar-custom"
          style={{ height: 350 }}
        >
          {notifications.length > 0 ? (
            notifications.map((notification) => (
              <NotificationCard key={notification.id} {...notification} />
            ))
          ) : (
            <div className="col text-center p-3">
              <p className="fs-18 fw-bolder">No notifications yet</p>
              <img
                className="py-1"
                src={emptyNotifications}
                alt="No Notifications"
                width={150}
              />
              <p className="fs-14 text-secondary text-break">
                {" "}
                Any notification you receive would show up here. You don’t have
                any requests right now
              </p>
            </div>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};
