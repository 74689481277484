import { useEffect, useState } from "react";
// import { useNavigate } from 'react-router';

import {
  createProperty,
  getSingleListingProperty,
  // getSingleProperty,
  updateProperty,
} from "@services/krent.service";
import ktoast from "@services/toast.service";

// import { PrimaryButton } from '@shared/buttons';
// import { Modal } from '@shared/controls/Modal';
import { ListingFormComponent } from "@shared/forms";
// import { ViewingDateComponent } from '@shared/forms';

import {
  // ModalStep1,
  Step1,
  Step2,
  // Step3,
  Step4,
  Step5,
  Step6,
  Step7,
  Step8,
  Step9,
  // ModalStep1,
  // ModalStep2,
} from "./steps";
import { useLocation } from "react-router-dom";

export const ListRent = () => {
  const location: any = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const editID = searchParams.get("id");
  const [index, setIndex] = useState<number>(location?.state?.index || 0);

  const [loading, setLoading] = useState<boolean>(false);
  const [editLoading, setEditLoading] = useState<boolean>(false);

  // const [showModal, setShowModal] = useState<boolean>(false);
  // const [modalIndex, setModalIndex] = useState<number>(0);
  const [propertyName, setPropertyName] = useState<string>("");
  const [propertyDesc, setPropertyDesc] = useState<string>("");
  const [propertyType, setPropertyType] = useState<any>("");
  const [propertyCategory, setPropertyCategory] = useState<any>("");
  // const [spaceType, setSpaceType] = useState<string>('');
  // const [safetyFeatures, setSafetyFeatures] = useState<any>([])
  const [amenities, setAmenities] = useState<any>([]);
  // const [viewingDates, setViewingDates] = useState<any>([
  // 	<ViewingDateComponent key={1} />,
  // ]);
  const [guests, setGuests] = useState<number>();
  const [bedrooms, setBedrooms] = useState<number>();
  const [beds, setBeds] = useState<number>();
  const [bathrooms, setBathrooms] = useState<number>();
  const [floors, setFloors] = useState<number>();
  const [garages, setGarages] = useState<number>();
  const [size, setSize] = useState<number>();
  const [price, setPrice] = useState<number>();
  const [cautionFee, setCautionFee] = useState<number>();
  const [address, setAddress] = useState<string>();
  const [apartment, setApartment] = useState<string>();
  const [city, setCity] = useState<string>();
  const [state, setState] = useState<string>();
  const [country, setCountry] = useState<string>();
  const [videoUrl, setVideoUrl] = useState<string>();
  const [tourUrl, setTourUrl] = useState<string>();
  const [duration, setDuration] = useState<string>("yearly");
  const [tourIntrest, setTourIntrest] = useState<boolean>();
  const [affiliation, setAffiliation] = useState<string>();
  const [propertyCreated, setPropertyCreated] = useState<boolean>();
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [propertyId, setPropertyId] = useState("");
  const [flood_meter, setFlood_meter] = useState<string>();
  const [neighbour_story, setNeighbour_story] = useState<boolean>(false);
  const [geo, setGeo] = useState<[]>();
  const [serviceCharge, setServiceCharge] = useState<[]>();
  const [serviceChargeDuration, setServiceChargeDuration] = useState<string>();
  const [toilets, setToilets] = useState<[]>();
  const [virtual_tour_date_request, setVirtual_tour_date_request] =
    useState<any>();
  const [insuranceOptions, setInsuranceOptions] = useState<any>([]);
  const [hasLegal, setHasLegal] = useState<string>("");
  const [legalName, setLegalName] = useState<string>("");
  const [legalEmail, setLegalEmail] = useState<string>("");
  const [legalContact, setLegalContact] = useState<any>("");
  const [owner, setOwner] = useState("");

  const [agencyFee, setAgencyFee] = useState<string>("5%");
  // const navigate = useNavigate();

  const onPrev = () => {
    setIndex(index - 1);
  };

  const onNext = () => {
    setIndex(index + 1);
  };

  // const modalSteps = [
  // 	modalStep1({ viewingDates, setViewingDates, setModalIndex }),
  // 	modalStep2({ setShowModal, setModalIndex }),
  // ];

  // const showM = () => {
  // 	const payload = {
  // 		title: propertyName,
  // 		summary: propertyDesc,
  // 		category: propertyCategory,
  // 		kind: propertyType,
  // 		space: spaceType,
  // 		address: `${apartment}, ${address}, ${city}, ${state}, ${country}`,
  // 		price: price,
  // 		currency: 'NGN',
  // 		description: propertyDesc,
  // 		intent: 'rent',
  // 		cycle: duration,
  // 		guests: guests,
  // 		beds: beds,
  // 		bedrooms: bedrooms,
  // 		bathrooms: bathrooms,
  // 		virtual_tour_url: tourUrl,
  // 		video_url: videoUrl,
  // 	};

  // 	console.log(payload);

  // 	setLoading(true);

  // 	createProperty(payload)
  // 		.then(() => {
  // 			navigate(
  // 				{
  // 					pathname: '/listings/',
  // 				},
  // 				{
  // 					state: {
  // 						showToast: true,
  // 						toastMessage: 'Listing published successfully',
  // 					},
  // 					replace: true,
  // 				}
  // 			);
  // 		})
  // 		.catch((err) => {
  // 			console.log(err);
  // 			setShowModal(false);
  // 			ktoast.error(err.errors[0].message);
  // 		})
  // 		.finally(() => {
  // 			setLoading(false);
  // 		});
  // };
  const getLegalDetails = (
    hasLegal: string,
    legalName: string,
    legalEmail: string,
    legalContact: string
  ) => {
    if (hasLegal === "no") {
      return {
        useKrentLegalTeam: false,
        legalDetails: {
          name: legalName,
          email: legalEmail,
          phone: legalContact,
        },
      };
    }

    return {
      useKrentLegalTeam: true,
    };
  };
  const handleCreate = () => {
    setLoading(true);
    const calculatedAgencyFee = price ? price * 0.05 : 0;
    const insuranceCompanyIds: string[] = insuranceOptions.map(
      (option: any) => option.value
    );

    const payloads = {
      title: propertyName,
      summary: propertyDesc,
      category: propertyCategory,
      kind: propertyType,
      space: "612a183ec1976d6838debb2e",
      address,
      location2: {
        apartment,
        city,
        state,
        country,
        geo,
        formatted_address: address,
      },
      price: price,
      cautionFee,
      currency: "NGN",
      description: propertyDesc,
      intent: "long lease",
      cycle: duration,
      guests: guests,
      beds: beds,
      bedrooms: bedrooms,
      bathrooms: bathrooms,
      virtual_tour_url: tourUrl,
      video_url: videoUrl,
      // virtual_tour: tourIntrest,
      affiliation,
      amenities,
      floors,
      garages,
      neighbour_story,
      flood_meter,
      service_charge: serviceCharge,
      service_charge_duration: serviceChargeDuration,
      toilets,
      isPropertyManager: owner,
      agencyFee: calculatedAgencyFee,
      insuranceCompanies: insuranceCompanyIds,
      ...getLegalDetails(hasLegal, legalName, legalEmail, legalContact),
    };

    if (editID) {
      updateProperty(editID, {
        ...payloads,
        published: false,
        kind: propertyType._id,
        category: propertyCategory._id,
      })
        .then(() => {
          setPropertyId(editID);
          setPropertyCreated(true);
          setShowSuccessModal(true);
        })
        .catch((err) => {
          ktoast.error(err.errors[0].message);
        })
        .finally(() => {
          setLoading(false);
        });
      return;
    }

    createProperty(payloads)
      .then((res: any) => {
        setPropertyId(res?.data.id);
        setPropertyCreated(true);
        setShowSuccessModal(true);
        // onNext();
      })
      .catch((err) => {
        console.log(err);

        ktoast.error(err.errors[0].message);
      })
      .finally(() => {
        console.log(propertyId);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (editID) {
      setEditLoading(true);
      let abortController = new AbortController();
      getSingleListingProperty(editID, abortController.signal)
        .then((res: any) => {
          // setEditData(res.data);
          setPropertyName(res.data?.title);
          setPropertyDesc(res.data?.summary);
          setPropertyType(res.data?.kind);
          setPropertyCategory(res.data.category);
          setApartment(res.data?.location2.apartment);
          setFloors(res.data?.floors);
          setAddress(res.data?.location2.formatted_address);
          setCity(res.data?.location2.city);
          setState(res.data?.location2.state);
          setCountry(res.data?.location2.country);
          setGeo(res.data?.location2.geo);
          setPrice(res.data?.price);
          setCautionFee(res.data?.cautionFee);
          setPropertyDesc(res.data?.description);
          setGuests(res.data?.guests);
          setBeds(res.data?.beds);
          setBedrooms(res.data?.bedrooms);
          setBathrooms(res.data?.bathrooms);
          setVideoUrl(res.data?.video_url);
          setTourUrl(res.data?.virtual_tour_url);
          setAffiliation(res.data?.affiliation);
          setAmenities(res.data?.amenities);
          setFlood_meter(res.data?.flood_meter);
          setNeighbour_story(res.data?.neighbour_story);
          setServiceCharge(res.data?.service_charge);
          setServiceChargeDuration(res.data?.service_charge_duration);
          setToilets(res.data?.toilets);
          setFloors(res.data?.floors);
          setGarages(res.data?.garages);
          setOwner(res.data?.isPropertyManager);
          setHasLegal(res.data?.useKrentLegalTeam ? "yes" : "no");
          setLegalContact(res?.data?.legalDetails?.phone);
          setLegalName(res?.data?.legalDetails?.name);
          setLegalEmail(res?.data?.legalDetails?.email);

          setEditLoading(false);
        })
        .catch((err) => {
          console.log(err.errors[0].message);
          setEditLoading(false);
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [editID]);

  if (editLoading) {
    return <h3>Loading...</h3>;
  }

  return (
    <div style={{ maxHeight: "90vh", overflow: "scroll" }}>
      <ListingFormComponent index={index}>
        {/* setShowModal={setShowModal} */}
        <Step1
          propertyName={propertyName}
          setPropertyName={setPropertyName}
          propertyDesc={propertyDesc}
          setPropertyDesc={setPropertyDesc}
          onPrev={onPrev}
          onNext={onNext}
        />
        <Step2
          propertyType={propertyType}
          setPropertyType={setPropertyType}
          propertyCategory={propertyCategory}
          setPropertyCategory={setPropertyCategory}
          onPrev={onPrev}
          onNext={onNext}
          affiliation={affiliation}
          setAffiliation={setAffiliation}
          editID={editID}
          owner={owner}
          setOwner={setOwner}
        />
        {/* <Step3
          spaceType={spaceType}
          setSpaceType={setSpaceType}
          onPrev={onPrev}
          onNext={onNext}
        /> */}
        <Step4
          guests={guests}
          setGuests={setGuests}
          bedrooms={bedrooms}
          setBedrooms={setBedrooms}
          bathrooms={bathrooms}
          setBathrooms={setBathrooms}
          floors={floors}
          setFloors={setFloors}
          garages={garages}
          setGarages={setGarages}
          beds={beds}
          setBeds={setBeds}
          size={size}
          setSize={setSize}
          toilets={toilets}
          setToilets={setToilets}
          onPrev={onPrev}
          onNext={onNext}
        />
        <Step5
          price={price}
          setPrice={setPrice}
          setDuration={setDuration}
          serviceCharge={serviceCharge}
          setServiceCharge={setServiceCharge}
          serviceChargeDuration={serviceChargeDuration}
          setServiceChargeDuration={setServiceChargeDuration}
          agencyFee={agencyFee}
          setAgencyFee={setAgencyFee}
          onPrev={onPrev}
          onNext={onNext}
        />
        <Step6
          insuranceOptions={insuranceOptions}
          setInsuranceOptions={setInsuranceOptions}
          hasLegal={hasLegal}
          setHasLegal={setHasLegal}
          legalName={legalName}
          legalEmail={legalEmail}
          legalContact={legalContact}
          setLegalName={setLegalName}
          setLegalEmail={setLegalEmail}
          setLegalContact={setLegalContact}
          onPrev={onPrev}
          onNext={onNext}
          cautionFee={cautionFee}
          setCautionFee={setCautionFee}
        />
        <Step7
          // safetyFeatures={safetyFeatures}
          // setSafetyFeatures={setSafetyFeatures}
          amenities={amenities}
          setAmenities={setAmenities}
          onPrev={onPrev}
          onNext={onNext}
        />
        <Step8
          address={address}
          setAddress={setAddress}
          city={city}
          setCity={setCity}
          state={state}
          setState={setState}
          country={country}
          setCountry={setCountry}
          apartment={apartment}
          setApartment={setApartment}
          onPrev={onPrev}
          onNext={onNext}
          handleCreate={handleCreate}
          showSuccessModal={showSuccessModal}
          setShowSuccessModal={setShowSuccessModal}
          setNeighbour_story={setNeighbour_story}
          flood_meter={flood_meter}
          setFlood_meter={setFlood_meter}
          setGeo={setGeo}
          editID={editID}
        ></Step8>
        <Step9
          videoUrl={videoUrl}
          setVideoUrl={setVideoUrl}
          tourUrl={tourUrl}
          setTourUrl={setTourUrl}
          tourIntrest={tourIntrest}
          setTourIntrest={setTourIntrest}
          setLoading={setLoading}
          loading={loading}
          onPrev={onPrev}
          propertyId={propertyId}
          propertyCreated={propertyCreated}
          affiliation={affiliation}
          virtualTourDate={virtual_tour_date_request}
          setVirtualTourDate={setVirtual_tour_date_request}
        ></Step9>
      </ListingFormComponent>

      {/* {showModal && (
        <Modal show={showModal} onClose={() => setShowModal(false)}>
          <div className='col px-4'>
            <h6 className='fw-bold fs-20'>
              Are you ready to publish this property?
            </h6>
            <p className='light-text'>
              Once you publish this property, Krent will review your listing and
              your listing would go live once it is approved.
            </p>

            <div className='row'>
              <div className='col-6'>
                <PrimaryButton
                  text='Publish'
                  loading={loading}
                  type='primary'
                  click={showM}
                />
              </div>
              <div className='col-6'>
                <PrimaryButton
                  text='Do this later'
                  type='outline'
                  click={() => setShowModal(false)}
                />
              </div>
            </div>
          </div>
        </Modal>
      )} */}
    </div>
  );
};
