import { setHours, setMinutes } from "date-fns";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";

interface ScheduleInspectionProps {
  scheduleBooking: (e: React.FormEvent<HTMLFormElement>) => void;
  fromDate: Date | null;
  setFromDate: any;
  datePickerMinDate: Date;
  toDate: Date | null;
  setToDate: any;
  acceptLoading: boolean;
  skipInspection?: () => void;
}

export const ScheduleInspection: React.FC<ScheduleInspectionProps> = ({
  scheduleBooking,
  fromDate,
  setFromDate,
  datePickerMinDate,
  toDate,
  setToDate,
  acceptLoading,
  skipInspection,
}) => {
  const [skipInspectionModal, showSkipInspectionModal] = useState(false);

  const excludeDates = (selectedDate: Date | null): Date[] => {
    if (!selectedDate) return [];
    return [selectedDate];
  };

  const filterPassedTime = (time: Date) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    if (
      currentDate.getDate() === selectedDate.getDate() &&
      currentDate.getMonth() === selectedDate.getMonth() &&
      currentDate.getFullYear() === selectedDate.getFullYear()
    ) {
      return currentDate.getTime() < selectedDate.getTime();
    }

    return true;
  };

  const getTimeSlots = () => {
    const times = [];
    for (let hour = 9; hour <= 17; hour++) {
      times.push(setHours(setMinutes(new Date(), 0), hour));
      times.push(setHours(setMinutes(new Date(), 30), hour));
    }
    return times;
  };

  return (
    <>
      <form className="mt-5" onSubmit={scheduleBooking}>
        <p className="fs-14">Please select a preferred date for inspection</p>

        <div className="form-group mb-3">
          <label className="form-label fs-14">Option 1</label>
          <div className="input-group">
            <span className="input-group-text input-group-text-0">
              <i className="iconly-Calendar icli fs-4"></i>
            </span>

            <div className="form-control form-control-0 border-start-0">
              <DatePicker
                selected={fromDate}
                onChange={(date: Date) => {
                  setFromDate(date);

                  if (toDate && date.toDateString() === toDate.toDateString()) {
                    const nextDay = new Date(date);
                    nextDay.setDate(nextDay.getDate() + 1);
                    setToDate(nextDay);
                  }
                }}
                placeholderText="dd-mm-yyyy"
                minDate={datePickerMinDate}
                className="form-control form-control-0 border-0"
                showYearDropdown
                showMonthDropdown
                showTimeSelect
                excludeDates={excludeDates(toDate)}
                dateFormat="dd-MM-yyyy h:mm aa"
                timeFormat="h:mm aa"
                timeIntervals={30}
                includeTimes={getTimeSlots()}
                filterTime={filterPassedTime}
                timeCaption="Time"
              />
            </div>
          </div>
        </div>

        <div className="form-group mb-3">
          <label className="form-label fs-14">Option 2</label>
          <div className="input-group">
            <span className="input-group-text input-group-text-0">
              <i className="iconly-Calendar icli fs-4"></i>
            </span>

            <div className="form-control form-control-0 border-start-0">
              <DatePicker
                selected={toDate}
                onChange={(date: Date) => {
                  if (
                    fromDate &&
                    date.toDateString() === fromDate.toDateString()
                  ) {
                    const nextDay = new Date(date);
                    nextDay.setDate(nextDay.getDate() + 1);
                    setToDate(nextDay);
                  } else {
                    setToDate(date);
                  }
                }}
                placeholderText="dd-mm-yyyy"
                className="form-control form-control-0 border-0"
                showYearDropdown
                showMonthDropdown
                showTimeSelect
                excludeDates={fromDate ? [fromDate] : []}
                minDate={datePickerMinDate}
                dateFormat="dd-MM-yyyy h:mm aa"
                timeFormat="h:mm aa"
                timeIntervals={30}
                includeTimes={getTimeSlots()}
                filterTime={filterPassedTime}
                timeCaption="Time"
                onCalendarOpen={() => {
                  if (!toDate && fromDate) {
                    const suggestedDate = new Date(fromDate);
                    suggestedDate.setDate(suggestedDate.getDate() + 1);
                    setToDate(suggestedDate);
                  }
                }}
                dayClassName={(date) =>
                  fromDate && date.toDateString() === fromDate.toDateString()
                    ? "disabled-date"
                    : "enabled-date"
                }
                popperProps={{
                  modifiers: [
                    {
                      name: "preventOverflow",
                      options: {
                        enabled: true,
                      },
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>

        <div className="d-grid">
          <button
            className="btn btn-primary rounded-pill px-4 py-3"
            disabled={acceptLoading}
          >
            {acceptLoading ? (
              <span className="spinner-border spinner-border-sm"></span>
            ) : (
              "Confirm"
            )}
          </button>
        </div>
      </form>
      <div className="d-grid mt-3">
        <button
          className="btn btn-secondary rounded-pill px-4 py-3"
          onClick={() => showSkipInspectionModal(true)}
        >
          Skip Inspection
        </button>
      </div>
      <Modal
        show={skipInspectionModal}
        onHide={() => showSkipInspectionModal(false)}
        backdrop="static"
        centered
      >
        <div className="p-4">
          <div>
            <h5>Are you sure you want to skip inspection?</h5>
            <p>This action cannot be undone.</p>
          </div>
          <div className="flex space-x-2 mt-4 float-right">
            <button
              className="btn rounded-pill px-4 py-2"
              onClick={() => showSkipInspectionModal(false)}
            >
              Cancel
            </button>
            <button
              className="btn btn-secondary rounded-pill px-4 py-2"
              disabled={acceptLoading}
              onClick={skipInspection}
            >
              {acceptLoading ? (
                <span className="spinner-border spinner-border-sm"></span>
              ) : (
                "Skip Inspection"
              )}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
