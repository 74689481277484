import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { DeveloperDashboardLayout } from '@layouts/DeveloperDashboardLayout'
import { DeveloperPropertyCard } from '@shared/cards/developer-property'
import buildingImg from '../../../assets/images/building.png'
import propertyIcon from '../../../assets/icons/prop.svg'
import rulerIcon from '../../../assets/icons/ruler.svg'
import binIcon from '../../../assets/icons/bin.svg'
import {
  deleteDeveloperSingleProject,
  getAllDeveloperProjectProperties,
  getSingleProjectDetails,
} from '@services/krentDeveloper'
import { Modal } from 'react-bootstrap'

const ProjectDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const [project, setProject] = useState<any>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [properties, setProperties] = useState<any[]>([])
  const [isDeleting, setIsDeleting] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showDeleteSuccessModal, setShowDeleteSuccessModal] = useState(false)

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    const fetchProjectDetails = async () => {
      try {
        const response = await getSingleProjectDetails(id!, signal)
        setProject(response.data || [])
        setLoading(false)
      } catch (err) {
        if (err instanceof Error) {
          if (err.name === 'AbortError') {
            console.log('Request was aborted')
          } else {
            setError('Failed to fetch project details')
          }
        } else {
          setError('An unknown error occurred')
        }
        setLoading(false)
      }
    }

    const fetchProjectProperties = async () => {
      try {
        if (id) {
          const response = await getAllDeveloperProjectProperties(id, signal)
          setProperties((response.data as any) || [])
        }
      } catch (err) {
        console.error('Failed to fetch project properties:', err)
      }
    }

    fetchProjectDetails()
    fetchProjectProperties()

    return () => {
      controller.abort()
    }
  }, [id])

  const handleEditDetails = () => {
    navigate('/developer/project-form', { state: { project } })
  }

  const handleAddProperty = () => {
    navigate(`/developer/add-property`, { state: { projectId: id } })
  }
  const handleDeleteProject = async () => {
    if (!id) return

    setIsDeleting(true)
    try {
      await deleteDeveloperSingleProject(id, {
        projectName: project?.projectName,
      })
      setShowDeleteModal(false)
      setShowDeleteSuccessModal(true)

      setTimeout(() => {
        setShowDeleteSuccessModal(false)
        navigate('/developer-projects')
      }, 10000)
    } catch (err) {
      console.error('Failed to delete project:', err)
      setError('Failed to delete project. Please try again.')
    } finally {
      setIsDeleting(false)
    }
  }
  if (loading) return <div>Loading...</div>
  if (error) return <div>{error}</div>
  if (!project) return <div>No project found</div>
  return (
    <DeveloperDashboardLayout title="My Projects">
      <>
        <div className="flex items-center gap-2">
          <p>Home</p>
          <i className="fa-light fa-angle-right"></i>
          <p className="">Dashboard</p>
          <i className="fa-light fa-angle-right"></i>
          <p>Project</p>
          <i className="fa-light fa-angle-right"></i>
          <p className="text-primary">Projects Details</p>
        </div>
        <div className="w-max ml-auto flex gap-3 my-4">
          <button
            className="fw-bold text-white bg-primary rounded-10 p-2 px-3 flex gap-2 items-center"
            onClick={handleEditDetails}
          >
            <i className="fa-sharp-duotone fa-solid fa-pen-field"></i>
            Edit details
          </button>
          <button
            className="fw-bold delete-btn rounded-10 p-2 px-3 flex gap-2 items-center"
            onClick={() => setShowDeleteModal(true)}
            disabled={isDeleting}
          >
            <i className="fa-solid fa-trash-can"></i>
            {'Delete project'}
          </button>
        </div>

        <div className="flex my-10 gap-10 items-start ">
          <div className="flex items-center gap-10 w-full">
            <i className="fa fa-angle-left text-white bg-primary px-2 py-1 rounded-full fa-solid"></i>

            <div className=" " style={{ width: '80%' }}>
              <img
                src={buildingImg}
                className="proj-img rounded-b-15"
                alt={'building'}
              />
            </div>
            <i className="fa fa-angle-right text-white bg-primary px-2 py-1 rounded-full fa-solid"></i>
          </div>
          <div className="w-full">
            <h5
              className={`fw-bold w-max rounded-50 px-3 fs-12 mb-3 ${
                project.projectTimeline === 'previous'
                  ? 'previous'
                  : project.projectTimeline === 'current'
                  ? 'current'
                  : 'future'
              }`}
            >
              <span className="capitalize">{project.projectTimeline} </span>
              project
            </h5>
            <div className="flex gap-3">
              {project.projectPropertiesType?.map(
                (item: any, index: number) => (
                  <h6
                    className="uppercase bg-primary-light w-max rounded-2 p-2 fs-12"
                    key={index}
                  >
                    {item ?? ''}
                  </h6>
                ),
              )}
            </div>
            <h4 className="my-3">{project.projectName}</h4>

            <p className="gap-2 flex items-center my-3">
              <i className="fa fa-location-dot text-primary fa-regular"></i>
              {project.address}
            </p>
            <div className="my-3">
              <h6 className="my-2">Project description</h6>
              <p className="" style={{ width: '80%' }}>
                {project.description}
              </p>
            </div>
            <div className="flex gap-20 items-center my-3">
              <div className="flex gap-1">
                <img src={propertyIcon} alt="propertyIcon" />
                <h6>{project.properties?.length} Properties</h6>
              </div>
              <div className="flex  gap-1">
                <img src={rulerIcon} alt="propertyIcon" />
                <h6>{project.projectSize} sqft</h6>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="flex justify-between mb-4">
            <h5>Properties in this project</h5>
            <button
              className="fw-bold text-white bg-primary rounded-10 p-2 px-3"
              onClick={handleAddProperty}
            >
              {' '}
              + &nbsp; Add property to project
            </button>
          </div>
          {properties.length < 1 ? (
            <div className="text-center">No property in this project</div>
          ) : (
            <div className="projects-div">
              {properties.map((p, idx) => (
                <div key={idx}>
                  <DeveloperPropertyCard property={p} />
                </div>
              ))}
            </div>
          )}
        </div>
        <Modal
          show={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          dismissible={false}
        >
          <div className=" p-4">
            <img src={binIcon} className=" " alt={'bin'} />
            <h6 className="my-4">Are you sure you want to delete project?</h6>
            <p>
              This action will permanently delete this project. This cannot be
              undone.
            </p>
            <div className="flex float-right gap-4 my-4">
              <button
                className="bg-transparent fw-600"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteProject}
                className="rounded-delete-btn"
              >
                Delete forever
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          show={showDeleteSuccessModal}
          onHide={() => setShowDeleteSuccessModal(false)}
          dismissible={false}
        >
          <div className="p-4 text-center">
            <img src={binIcon} className="text-center" alt={'bin'} />
            <h4 className="my-4">Project deleted successfully!</h4>
            <p>This window will close in 10 seconds.</p>
          </div>
        </Modal>
      </>
    </DeveloperDashboardLayout>
  )
}

export default ProjectDetails
