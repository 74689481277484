import React, { Fragment } from "react";

export function DocumentSigned(
  transaction: any,
  user: any,
  setShowExtendBooking: any,
  setOpenIncident: (arg: boolean) => void
): React.ReactNode {
  return (
    <Fragment>
      <button
        className="btn btn-link fs-12"
        onClick={() => {
          setOpenIncident(true);
        }}
      >
        Report a problem
      </button>
      <button
        className="btn btn-link fs-12 hidden"
        onClick={() => setShowExtendBooking(true)}
      >
        Renew Your Rent
      </button>
      {transaction.renter?.id === user?.id ? (
        <div className="mb-3">
          {/* <p
            className='fs-14 cursor-pointer dropdown-item'
            onClick={() => setShowExtendBooking(true)}
          >
            Renew Rent
          </p> */}
          <p className="fs-14 alert alert-success" role="alert">
            Your payment was successful.
          </p>
          <>
            {!transaction?.agreementSigned && (
              <div className="alert alert-primary" role="alert">
                <p className="fs-14">
                  <i className="iconly-Paper align-text-bottom icli fs-22 me-1"></i>
                  <a
                    className="fs-14"
                    target="_blank"
                    rel="noreferrer"
                    href={transaction.renterSigningLink}
                  >
                    Click to sign legal document
                  </a>
                </p>
              </div>
            )}
            {transaction.agreementDocumentUrl && (
              <button className="btn btn-success">
                <i className="iconly-Paper align-text-bottom icli fs-22 me-1"></i>
                <a
                  href={transaction.agreementDocumentUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: "none",
                    color: "white",
                  }}
                >
                  Download Signed Document
                </a>
              </button>
            )}
          </>
        </div>
      ) : (
        <>
          <div className="mb-3 ">
            <div className="alert alert-success" role="alert">
              <p className="fs-14">Payment has been completed.</p>
            </div>
            {!transaction?.agreementSigned && (
              <div className="alert alert-primary" role="alert">
                <p className="fs-14">
                  <i className="iconly-Paper align-text-bottom icli fs-22 me-1"></i>
                  <a
                    className="fs-14"
                    target="_blank"
                    rel="noreferrer"
                    href={transaction.landlordSigningLink}
                  >
                    Click to sign legal document
                  </a>
                </p>
              </div>
            )}
          </div>
          {transaction.agreementDocumentUrl && (
            <button className="btn btn-success">
              <i className="iconly-Paper align-text-bottom icli fs-22 me-1"></i>
              <a
                href={transaction.agreementDocumentUrl}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "none",
                  color: "white",
                }}
              >
                Download Signed Document
              </a>
            </button>
          )}
        </>
      )}
    </Fragment>
  );
}
