// import {
//   PropertyKind,
//   // PropertyListResponse,
//   // PropertyLocationResponse,
// } from '@interfaces/properties.interface';
import { DashboardLayout, BaseLayout } from "@layouts/index";
import { NewsCardList } from "@lists/NewsCardList";
import { TestimonialCardList } from "@lists/TestimonialCardList";
import {
  getProfile,
  // getProfile,
  getProperties,
  searchPropByKey,
  // getPropertyKinds,
  // getTopLocations,
  // searchPropByKey,
} from "@services/krent.service";
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import {
  FiltersCard,
  SearchCard2,
  //  SearchCard
} from "@shared/cards";
import {
  // useContext,
  useEffect,
  useState,
} from "react";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  //  useNavigate
} from "react-router-dom";
// import {LocationCardList} from '../../components/lists/LocationCardList';
import { PropertyCardList } from "../../components/lists/PropertyCardList";
// import {useSessionStorage} from '../../hooks';
import { testimonials } from "../Landing2/data";
import {
  useSearchParams,
  //  createSearchParams
} from "react-router-dom";
// import {BaseApiResponse, PaginatedResponse} from '@interfaces/app.interface';
import { useSessionStorage } from "../../hooks";
import Pagination from "components/Pagination";
// import GlobalSearch from 'components/GlobalSearch';
import PopularLocation from "components/PopularLocation";
import { SectionHeading } from "components/SectionHeading";
import { NewsListResponse } from "@interfaces/news.interface";
import {
  // addPageToSessionStorage,
  getPageFromSessionStorage,
} from "@utils/helpers";
import Modal from "react-responsive-modal";

import GetMeta from "@pages/SeoPages/GetMeta";
import RadioSearchNav from "@shared/cards/RadioSearchNav";
import customFetch from "@services/axio";
// import RequestForm from 'components/GetHelp';
// import {Modal} from '@shared/controls/Modal';
// import { Me } from '@interfaces/profile.interface';
// import {Me} from '@interfaces/profile.interface';
// import {PreviousPageContext} from '@services/context';

// import { NewsListResponse } from '@interfaces/news.interface';
// import { getBlogNews } from '@services/blog.service';

export const Explore = () => {
  const [user] = useSessionStorage("user", {});
  const [userPendingTransactions, setUserPendingTransactions] = useState<any>();

  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  // const previousPath = useContext(PreviousPageContext);

  // const navigate = useNavigate();
  const location: any = useLocation();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env["REACT_APP_GOOGLE_API_KEY"] || "",
    libraries: ["places"],
  });
  // const urlParams = new URLSearchParams(location.search);

  // const initialIntent = urlParams.get('intent');

  const [loading, setLoading] = useState<boolean>(false);
  // const [loadingMore, setLoadingMore] = useState<boolean>(false);
  // const [properties, setProperties] = useState<PropertyListResponse[]>([]);
  // const [propertyKinds, setPropertyKinds] = useState<PropertyKind[]>([]);
  // const [topLocations, setTooLocations] = useState<PropertyLocationResponse[]>(
  //   []
  // );
  // const [nextPage, setNextPage] = useState<any>(1);
  const [page, setPage] = useState<any>(parseInt(getPageFromSessionStorage()));
  const [news, setNews] = useState<NewsListResponse[]>([]);
  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [closed, setClosed] = useState<boolean>(false);

  const [inprogressTransactions, setInprogressTransactions] = useState(0);
  const [pendingInspectionTransactions, setPendingInspectionTransactions] =
    useState(0);
  const [pendingPaymentTransactions, setPendingPaymentTransactions] =
    useState(0);
  const [userKyc, setUserKyc] = useState();

  const limit: any = 21;
  const [data, setData] = useState<any>({
    properties: [],
    current_page: 1,
    page_count: 0,
    total: 0,
    numOfPages: 1,
  });
  // const [propertyLocation, setPropertyLocation] = useState<string>('');

  const [autoCompleteLocation, setAutoCompleteLocation] = useState<string>("");
  const [autoCompleteState, setAutoCompleteState] = useState<any>(null);
  const onLoadState = (autocomplete: any) => {
    setAutoCompleteState(autocomplete);
  };
  const onStateChanged = () => {
    if (autoCompleteState !== null) {
      const state =
        autoCompleteState
          .getPlace()
          .address_components?.find((p: any) =>
            p.types.includes("sublocality_level_1")
          )?.long_name ||
        autoCompleteState
          .getPlace()
          .address_components?.find((p: any) =>
            p.types.includes("neighborhood")
          )?.long_name ||
        autoCompleteState
          .getPlace()
          .address_components?.find((p: any) => p.types.includes("locality"))
          ?.long_name ||
        autoCompleteState
          .getPlace()
          .address_components?.find((p: any) =>
            p.types.includes("administrative_area_level_1")
          )?.long_name;

      setAutoCompleteLocation(state);

      // console.log(autoCompleteState.getPlace().address_components);
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };
  // const [autoCompleteLocation, setAutoCompleteLocation] = useState<string>('');
  // const [news, setNews] = useState<NewsListResponse[]>([]);

  // const [bedroom, setBedroom] = useState<string>('');
  // const [bed, setBed] = useState<string>('');
  // const [bathroom, setBathroom] = useState<string>('');
  // const [intent, setIntent] = useState<string | any>('');

  // Fecth properties function
  const fetchProperties = () => {
    setLoading(true);

    const params: { [x: string]: string } = {};
    queryParams.forEach((value, key: string) => {
      params[key] = value;
    });

    let abortController: AbortController = new AbortController();

    getProperties({ ...params, limit, page }, abortController.signal)
      .then((res: any) => {
        const { page_count, data, current_page, total } = res;
        setData({
          properties: data,
          page_count,
          total,
          current_page,
          numOfPages: Math.ceil(total / limit),
        });
        // setNextPage(current_page + 1);
        // setProperties(res.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      abortController.abort();
    };
  };

  // const handleNavigateToInvoice = () => {
  //   setShowModal(false);
  //   navigate(`/invoice`);
  // };

  useEffect(() => {
    let abortController: AbortController = new AbortController();
    getProfile(abortController.signal)
      .then((re: any) => {
        setUserPendingTransactions(re?.data);
      })
      .catch((error: any) => {
        console.log("Error: ", error);
      });
  }, []);

  useEffect(() => {
    if (user.role !== "user") return;
    if (!userPendingTransactions) return;

    const { pendingTransactions } = userPendingTransactions;
    if (!pendingTransactions) return;

    const inprogress = pendingTransactions.filter(
      (item: any) => item.status === "progress"
    );
    const pendingInspection = pendingTransactions.filter(
      (item: any) => item.status === "awaiting-inspection"
    );
    const pendingPayment = pendingTransactions.filter(
      (item: any) => item.status === "customer-accepted"
    );

    const inprogressCount = inprogress.length > 0 ? inprogress[0].count : 0;
    const pendingInspectionCount =
      pendingInspection.length > 0 ? pendingInspection[0].count : 0;
    const pendingPaymentCount =
      pendingPayment.length > 0 ? pendingPayment[0].count : 0;

    setInprogressTransactions(inprogressCount);
    setPendingInspectionTransactions(pendingInspectionCount);
    setPendingPaymentTransactions(pendingPaymentCount);

    if (
      !closed &&
      (inprogressCount > 0 ||
        pendingInspectionCount > 0 ||
        pendingPaymentCount > 0)
    ) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [userPendingTransactions]);

  // This takes effect if the search was a key word search
  useEffect(() => {
    // const {current_page, page_count, total} = location?.state?.searchResult;

    if (location.state?.searchResult && location?.state !== null) {
      setData({
        ...data,
        current_page: location?.state?.searchResult?.current_page,
        page_count: location?.state?.searchResult?.page_count,
        total: location?.state?.searchResult?.total,
        numOfPages: Math.ceil(location?.state?.searchResult?.total / limit),
        properties: location?.state?.searchResult?.data,
      });
    }
  }, [location.state]);

  useEffect(() => {
    //  this takes effect when page is been change to trigger rerender

    if (location.state?.searchResult && location?.state !== null) {
      const fetchByPage = async () => {
        try {
          const result = await searchPropByKey(location?.state?.keyWord, page);

          setData({
            ...data,
            current_page: result?.current_page,
            page_count: result?.page_count,
            total: result?.total,
            numOfPages: Math.ceil(result?.total / limit),
            properties: result?.data,
          });
        } catch (error) {
          console.log("Error during search:", error);
        }
      };
      fetchByPage();
    }
  }, [page]);

  // if no global search this takes effect
  // useEffect(() => {
  //   if (!location.state || location.state === null) {
  //     onSearch();
  //   }
  // }, [page]);

  // if no global search this takes effect
  useEffect(() => {
    // Update the 'intent' state if the query parameter changes
    if (!location.state?.searchResult || location.state === null) {
      fetchProperties();
    }
  }, [queryParams, location.pathname, page]);

  useEffect(() => {
    let abortController = new AbortController();

    const nNews = async () => {
      const url = process.env["REACT_APP_BLOG_BASE_URL"];

      try {
        const res = await fetch(`${url}`);
        let result = await res.json();
        setNews(result);
        return result;
      } catch (error) {
        console.log(error);
      }
    };
    nNews();
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    // Trigger click event on the button to open the offcanvas

    if (location.state?.filter) {
      const offcanvasButton: any = document.querySelector(
        '[data-bs-toggle="offcanvas"]'
      );
      if (offcanvasButton) {
        offcanvasButton.click();
      }
    }
  }, []);
  const getPersonalKycStatus = async () => {
    try {
      const resp = await customFetch.get("/users/kyc?type=PERSONAL");
      setUserKyc(resp?.data?.data);
    } catch (error: any) {
      // ktoast.error(error?.response?.data?.errors[0]?.message);
    }
  };
  useEffect(() => {
    getPersonalKycStatus();
  }, [user]);
  const params = new URLSearchParams(location.search);
  const intent = params.get("intent");
  // const kycStatus = undefined
  return user && user?._id ? (
    <DashboardLayout>
      <GetMeta type={intent} />

      {!userKyc && (
        <div className="my-4">
          <div
            className="flex justify-between p-3"
            style={{
              backgroundColor: "#FFDFDC",
              borderLeft: "4px solid #FF0000",
            }}
          >
            <div
              className="flex gap-3 items-center"
              style={{ color: "#FF0000" }}
            >
              <i className="fa-regular fa-user fa-2xl"></i>
              <div>
                <h6 className="m-0">Please complete your KYC.</h6>
                <p className="text-light-black">
                  Complete your KYC Process to verify your identity
                </p>
              </div>
            </div>
            <button
              className="bg-primary rounded-10 text-white fw-bold px-4"
              onClick={() => navigate("/settings?tab=kyc")}
            >
              Add KYC
            </button>
          </div>
        </div>
      )}

      <section className="container mt-5 mb-5">
        {/* <div className='mb-5'>
          <GlobalSearch />
        </div> */}
        {/* {isLoaded ? (
          <Autocomplete onLoad={onLoadState} onPlaceChanged={onStateChanged}>
            <input
              type='text'
              className='border-0 fw-bold form-control fs-16'
              value={propertyLocation}
              name='property location'
              onChange={(e) => {
                setPropertyLocation(e.target.value);
              }}
            />
          </Autocomplete>
        ) : (
          <input
            type='text'
            className='border-0 fw-bold form-control fs-16'
            value={propertyLocation}
            name='property location'
            onChange={(e) => {
              setPropertyLocation(e.target.value);
            }}
          />
        )} */}

        <h5 className="fw-bolder mb-3 pt-3">Filter Properties</h5>
        <div className=" col-12 rounded">
          {/* <SearchNav handleSearchTab={handleSearchTab} /> */}
          <div>
            <RadioSearchNav type={intent!} setPage={setPage} />
          </div>
          <SearchCard2
            extraClass="rounded-top-left-0 rounded-top-right-0"
            sizeClass="col-md-6 col-8"
            page={page}
            setPage={setPage}
          />
        </div>
        {/* <button
          className='btn btn-link fw-bold px-0 no-decoration'
          data-bs-toggle='offcanvas'
          data-bs-target='#offcanvasExample'
          aria-controls='offcanvasExample'
        >
          More Filters
        </button> */}
        {filtersLoaded && (
          <Link
            className="btn btn-link fw-bold px-0 text-danger ml-1 no-decoration"
            to={"/explore"}
            onClick={() => setFiltersLoaded(false)}
          >
            Clear Filters
          </Link>
        )}
      </section>
      <section className="container">
        {location.state?.searchResult ? (
          <div>
            <i className="fa fa-remove text-danger"></i>
            <Link
              className=" btn-link  px-0 text-danger ml-1 d-inline-block mt-1  no-decoration"
              to={
                window.location.pathname.includes("properties")
                  ? "/explore"
                  : "/properties"
              }
            >
              Clear search
            </Link>
          </div>
        ) : (
          ""
        )}
        {loading ? (
          <p>Loading....</p>
        ) : data?.properties?.length === 0 ? (
          <h4>
            We couldn't find any matches for your search. Please consider trying
            different keywords.
          </h4>
        ) : (
          <PropertyCardList properties={data.properties} loading={loading} />
        )}
        {/* {loadingMore && <p>Loading...</p>} */}

        <Pagination
          page={page}
          numOfPages={data.numOfPages}
          setPage={setPage}
          // changePage={changePage}
        />
      </section>

      <div
        className="offcanvas offcanvas-end"
        tabIndex={-1}
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title fw-bolder" id="offcanvasExampleLabel">
            Filters
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <FiltersCard
          isLoaded={isLoaded}
          autoCompleteLocation={autoCompleteLocation}
          setAutoCompleteLocation={setAutoCompleteLocation}
          Autocomplete={Autocomplete}
          onLoadState={onLoadState}
          onStateChanged={onStateChanged}
          page={page}
          limit={limit}
          setPage={setPage}
          setFiltersLoaded={setFiltersLoaded}
        />
      </div>
      {/* <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        dismissible={false}
      > */}
      <Modal
        center
        open={showModal}
        onClose={() => {
          setShowModal(false);
          setClosed(true);
        }}
        classNames={{
          // overlayAnimationIn: 'customEnterOverlayAnimation',
          // overlayAnimationOut: 'customLeaveOverlayAnimation',
          modalAnimationIn: "customEnterModalAnimation",
          modalAnimationOut: "customLeaveModalAnimation",
          modal: "customModal",
        }}
        animationDuration={500}
        aria-labelledby="summary-title"
        aria-describedby="summary-description"
      >
        <div className="container pb-3 mt-5 ">
          <div
            className=" p-3 d-flex align-items-center gap-2 mb-2"
            style={{ background: "#FEFCE8", color: "#854D0E" }}
          >
            <i
              className="fa fa-triangle-exclamation "
              style={{ color: "#EFDB11" }}
            ></i>
            <p className="fw-bold fs-12">Attention needed</p>
          </div>
          {inprogressTransactions > 0 && (
            <p className="fs-14">
              You have{" "}
              <span className="fw-bold ">{inprogressTransactions}</span> open{" "}
              {inprogressTransactions > 1 ? "transactions" : "transaction"}
            </p>
          )}
          {pendingPaymentTransactions > 0 && (
            <p className="fs-14">
              You have{" "}
              <span className="fw-bold ">{pendingPaymentTransactions}</span>{" "}
              {pendingPaymentTransactions > 1 ? "transactions" : "transaction"}{" "}
              pending payment.
            </p>
          )}
          {pendingInspectionTransactions > 0 && (
            <p className="fs-14">
              You have{" "}
              <span className="fw-bold ">{pendingInspectionTransactions}</span>{" "}
              {pendingInspectionTransactions > 1 ? "properties" : "property"}{" "}
              awaiting inspection.
            </p>
          )}
          <p className="fw-bold fs-13 text-secondary">
            Act now to complete or close the transaction to allow future
            property bookings.
          </p>
          <div className="d-flex">
            <Link
              to="/my-bookings"
              // data-bs-dismiss='modal'
              className="btn bg-primary rounded-pill px-md-5  py-2 text-white fw-bold mt-3"
              // onClick={handleNavigateToInvoice}
            >
              Take Action
            </Link>
            <button
              style={{ display: showModal ? "block" : "none" }}
              className="btn rounded-pill px-md-5 py-2 text-secondary fs-13 mt-3 ml-3"
              onClick={() => setShowModal(false)}
            >
              Do this later
            </button>
          </div>
        </div>
      </Modal>
      {/* {user && user.role === 'user' && <RequestForm />} */}
    </DashboardLayout>
  ) : (
    <BaseLayout>
      <GetMeta type={intent} />

      <section className="container mt-5 ">
        {/* <div className='mb-5'>
          <GlobalSearch />
        </div> */}

        <h5 className="fw-bolder mb-3">Filter Properties</h5>
        {/* <SearchCard
          location={'ikeja'}
          propertySize={'2'}
          propertyTypes={propertyKinds}
          price='20'
        /> */}
        <div className=" col-12   rounded">
          {/* <SearchNav handleSearchTab={handleSearchTab} /> */}
          <div className="d-lg-none">
            <RadioSearchNav type={intent!} setPage={setPage} />
          </div>
          <SearchCard2
            extraClass="rounded-top-left-0 rounded-top-right-0"
            sizeClass=" col-md-6 col-8"
            page={page}
            setPage={setPage}
          />
        </div>

        {/* <button
          className='btn btn-link fw-bold px-0 no-decoration t-canvas'
          data-bs-toggle='offcanvas'
          data-bs-target='#offcanvasExample'
          aria-controls='offcanvasExample'
        >
          Filters
        </button> */}
        {filtersLoaded && (
          <NavLink
            to={"/explore/"}
            className="btn btn-link text-danger fw-bold px-3 text-decoration-none"
            onClick={() => setFiltersLoaded(false)}
          >
            Clear Filters
          </NavLink>
        )}
      </section>

      <section className="container">
        {location.state ? (
          <div>
            <i className="fa fa-remove text-danger"></i>
            <Link
              className=" btn-link  px-0 text-danger ml-1 d-inline-block  no-decoration"
              to={
                window.location.pathname.includes("properties")
                  ? "/explore"
                  : "/properties"
              }
            >
              Clear search
            </Link>
          </div>
        ) : (
          ""
        )}
        <div>
          <h1 className="fs-20 lh-34 mb-3 mt-2">
            {intent === "shortlet"
              ? `Shortlet Property in Nigeria`
              : intent === "long lease"
              ? `Property for Rent in Nigeria`
              : intent === "sale"
              ? `Property for Sale in Nigeria`
              : ""}
          </h1>
        </div>
        {loading ? (
          <p>Loading....</p>
        ) : data.properties.length === 0 ? (
          <h4>
            We couldn't find any matches for your search. Please consider trying
            different keywords.
          </h4>
        ) : (
          <div>
            <div
              className="fs-14 mb-3 p-3  col-lg-6"
              style={{
                lineHeight: "2",
                fontWeight: "normal",
                backgroundColor: "#EFF1EF",
                color: "#171D1B",
                borderRadius: "8px",
              }}
            >
              <ul
                className="d-flex flex-wrap gap-3 "
                style={{
                  boxSizing: "border-box",
                  width: "100%",
                  paddingLeft: "0",
                }}
              >
                <li
                  className="list-unstyled"
                  style={{
                    // borderRight: '2px solid #6F7976',
                    paddingRight: "8px",
                  }}
                  onClick={() => setPage(1)}
                >
                  <Link
                    to={`/property-for-rent/house/in/lagos`}
                    style={{ color: "#00201B" }}
                  >
                    House for Rent in Lagos
                  </Link>
                </li>
                <li
                  className="list-unstyled"
                  style={{
                    // borderRight: '2px solid #6F7976',
                    paddingRight: "8px",
                  }}
                  onClick={() => setPage(1)}
                >
                  <Link
                    to={`/property-for-sale/house/in/lagos`}
                    style={{ color: "#00201B" }}
                  >
                    House for Sale in Lagos
                  </Link>
                </li>
                <li
                  className="list-unstyled"
                  style={{
                    // borderRight: '2px solid #6F7976',
                    paddingRight: "8px",
                  }}
                  onClick={() => setPage(1)}
                >
                  <Link
                    to={`/property-for-sale/land`}
                    style={{ color: "#00201B" }}
                  >
                    Lands in Nigeria
                  </Link>
                </li>
                <li
                  className="list-unstyled"
                  style={{
                    // borderRight: '2px solid #6F7976',
                    paddingRight: "8px",
                  }}
                  onClick={() => setPage(1)}
                >
                  <Link to={`/properties`} style={{ color: "#00201B" }}>
                    Properties in Nigeria
                  </Link>
                </li>
              </ul>
            </div>
            <PropertyCardList properties={data.properties} loading={loading} />
          </div>
        )}
        {/* {loadingMore && <p>Loading...</p>} */}

        <Pagination
          page={page}
          numOfPages={data.numOfPages}
          // changePage={changePage}
          setPage={setPage}
        />
      </section>

      <section className="container py-5 px-0 px-md-3">
        <PopularLocation />
      </section>

      <section className="py-5">
        <div className="container">
          <SectionHeading
            title="Blog Posts"
            text="Stay up to date with the latest from krent"
          />

          <NewsCardList lg={3} news={news.slice(0, 3)} />
        </div>
      </section>

      <section className="">
        <div className="container">
          <SectionHeading
            title="Testimonials"
            text="Take a look at what some our clients has to say"
          />

          <TestimonialCardList testimonials={testimonials} />
        </div>
      </section>

      <section className="pb-5">
        <div className="container text-center">
          <h4 className="fw-bolder">
            You're one step away from an amazing experience
          </h4>
          <div className="d-grid gap-3 d-md-block my-4">
            <Link
              to="/sign-in/"
              className="btn btn-custom-primary py-3 px-4 rounded-pill me-2 fs-14 fw-600"
            >
              List A Property
            </Link>
            <Link
              to="/explore/"
              className="btn btn-white border-dark py-3 px-3 rounded-pill fs-14 fw-600"
            >
              {" "}
              Browse Properties
            </Link>
          </div>
        </div>
      </section>

      <div
        className="offcanvas offcanvas-end"
        tabIndex={-1}
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header px-4">
          <h5 className="offcanvas-title fw-bolder" id="offcanvasExampleLabel">
            Filters
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>

        <FiltersCard
          isLoaded={isLoaded}
          autoCompleteLocation={autoCompleteLocation}
          setAutoCompleteLocation={setAutoCompleteLocation}
          Autocomplete={Autocomplete}
          onLoadState={onLoadState}
          onStateChanged={onStateChanged}
          page={page}
          limit={limit}
          setPage={setPage}
          setFiltersLoaded={setFiltersLoaded}
        />
      </div>
    </BaseLayout>
  );
};
