import {
  BaseApiResponse,
  DeveloperProjectsResponse,
} from "@interfaces/app.interface";
import { DevSingleProjectResponse } from "@utils/constants";
import { fetchCookie } from "@utils/cookies";
import { toast } from "react-toastify";
import { DevProfile } from "types";

const API_BASE_URL = process.env["REACT_APP_DEVELOPER_BASE_URL"];

type HttpMethod = "GET" | "POST" | "PATCH" | "DELETE" | "PUT";

interface RequestConfig {
  method: HttpMethod;
  path: string;
  params?: Record<string, string>;
  body?: any;
  requiresAuth?: boolean;
  headers?: any;
}

async function apiRequest<T>(
  config: RequestConfig,
  signal?: AbortSignal
): Promise<T> {
  const {
    method,
    path,
    params,
    body,
    requiresAuth = true,
    headers: customHeaders,
  } = config;

  const url = new URL(`${API_BASE_URL}${path}`);
  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      url.searchParams.append(key, value);
    });
  }

  const headers: HeadersInit = {
    "Content-Type": "application/json",
  };

  if (requiresAuth) {
    const token = fetchCookie("1Q_SPA");
    headers["Authorization"] = `Bearer ${token}`;
  }

  const finalHeaders = { ...headers, ...customHeaders };

  try {
    const response = await fetch(url.toString(), {
      method,
      headers: finalHeaders,
      body:
        body && finalHeaders["Content-Type"] === "application/json"
          ? JSON.stringify(body)
          : body,
      signal,
    });

    const data: BaseApiResponse<T> = await response.json();

    if (!response.ok) {
      toast.error(data.error || data.message);

      // throw new Error(data.error || "API request failed");
    }

    return data as any;
  } catch (error) {
    let errorMessage = "An error occurred while processing your request.";

    if (error instanceof Error) {
      errorMessage = error.message;
    }

    // Display error toast
    toast.error(errorMessage);

    // Re-throw the error for further handling if needed
    throw error;
  }
}

export const registerDeveloper = (
  payload: any
): Promise<BaseApiResponse<{}>> => {
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_DEVELOPER_BASE_URL"]}/auth/register`,
      {
        method: "POST",
        body: payload,
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data: BaseApiResponse<{}>) => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAllDeveloperProjects = (
  id: string,
  // signal: AbortSignal,
  timeline?: string
) =>
  apiRequest<DeveloperProjectsResponse>(
    {
      method: "GET",
      path: `/project/developer-projects/${id}`,
      params: timeline ? { timeline } : undefined,
    }
    // signal
  );

export const getDeveloperMetrics = (signal: AbortSignal) =>
  apiRequest<any>(
    {
      method: "GET",
      path: "/users/listing-metrics",
    },
    signal
  );
export const getAllDevelopersProjects = (
  limit: number,
  page: number,
  signal: AbortSignal
) =>
  apiRequest<DeveloperProjectsResponse>(
    {
      method: "GET",
      path: `/project/projects?timeline=all&page=${page}&limit=${limit}`,
    },
    signal
  );
export const getAllDevelopersProjectProperties = (
  id: string,
  signal: AbortSignal
) =>
  apiRequest<DeveloperProjectsResponse>(
    {
      method: "GET",
      path: `/project/developer-project/${id}/property`,
    },
    signal
  );

export const getSingleProjectDetails = (id: string, signal: AbortSignal) =>
  apiRequest<DevSingleProjectResponse>(
    {
      method: "GET",
      path: `/project/${id}`,
      requiresAuth: false,
    },
    signal
  );

export const createDeveloperProject = (payload: any) =>
  apiRequest<any>({
    method: "POST",
    path: "/project/create-project",
    body: payload,
  });

// export const uploadProjectImage = (id: string, payload: any) =>
//   apiRequest<any>({
//     method: "PUT",
//     path: `/project/upload-images/project/${id}`,
//     body: payload,
//     headers: {
//       "Content-Type": "multipart/form-data",
//     },
//   });
export const uploadProjectImage = (
  type: string,
  id: string,
  payload: any
): Promise<BaseApiResponse<{}>> => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_DEVELOPER_BASE_URL"]}/project/upload-images/${type}/${id}`,
      {
        headers: {
          Authorization: `Bearer ${_token}`,
        },
        method: "PUT",
        body: payload,
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data: BaseApiResponse<{}>) => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAllDeveloperProjectProperties = (
  id: string,
  signal: AbortSignal
) =>
  apiRequest<DeveloperProjectsResponse>(
    {
      method: "GET",
      path: `/project/developer-project/${id}/property`,
    },
    signal
  );

export const createDeveloperSingleProjectProperty = (payload: any) =>
  apiRequest<any>({
    method: "POST",
    path: "/project/create-property-for-project",
    body: payload,
  });

export const getDeveloperSingleProperty = (id: string, signal: AbortSignal) =>
  apiRequest<DeveloperProjectsResponse>(
    {
      method: "GET",
      path: `/properties/developer-property/${id}`,
    },
    signal
  );
export const editDeveloperSingleProjectProperty = (id: string, payload: any) =>
  apiRequest<any>({
    method: "PATCH",
    path: `/properties/developer-property/${id}`,
    body: payload,
  });

export const deleteDeveloperSingleProjectProperty = (
  id: string,
  payload: any
) =>
  apiRequest<any>({
    method: "DELETE",
    path: `/properties/developer-property/${id}`,
    body: payload,
  });
export const editDeveloperSingleProject = (id: string, payload: any) =>
  apiRequest<any>({
    method: "PATCH",
    path: `/project/projects/${id}`,
    body: payload,
  });

export const deleteDeveloperSingleProject = (id: string, payload: any) =>
  apiRequest<any>({
    method: "DELETE",
    path: `/project/projects/${id}`,
    body: payload,
  });

export const getDeveloperProfileInfo = (signal: AbortSignal) =>
  apiRequest<DevProfile>(
    {
      method: "GET",
      path: `/users/profile-info`,
      requiresAuth: true,
    },
    signal
  );
export const getDeveloperReviews = (id: string, signal: AbortSignal) =>
  apiRequest<any>(
    {
      method: "GET",
      path: `/reviews/developers/${id}/reviews`,
      requiresAuth: true,
    },
    signal
  );
export const createDeveloperReviews = (
  developerId: string,
  payload: any,
  signal: AbortSignal
) =>
  apiRequest<any>(
    {
      method: "POST",
      path: `/reviews/developers/${developerId}/reviews`,
      body: payload,
    },
    signal
  );

export const getAllAgents = (signal: AbortSignal) =>
  apiRequest<any>(
    {
      method: "GET",
      path: `/users/user-type?role=agent`,
    },
    signal
  );
export const getUserInterests = (signal: AbortSignal) =>
  apiRequest<any>(
    {
      method: "GET",
      path: `/developer-property-transaction/user-interests`,
    },
    signal
  );

export const getUserPurchasedInterests = (signal: AbortSignal) =>
  apiRequest<any>(
    {
      method: "GET",
      path: `/developer-property-transaction/purchases
`,
    },
    signal
  );

export const getDeveloperSalesPipeline = (signal: AbortSignal) =>
  apiRequest<any>(
    {
      method: "GET",
      path: `/developer-property-transaction/developer/sales-pipeline
  `,
    },
    signal
  );

export const createUserInterest = (payload: any) =>
  apiRequest<any>({
    method: "POST",
    path: `/developer-property-transaction/user-interest`,
    body: payload,
  });
export const getDeveloperShownInterestProperties = (signal: AbortSignal) =>
  apiRequest<any>(
    {
      method: "GET",
      path: `/developer-property-transaction/developer-user-interests
  `,
    },
    signal
  );

export const createPurchaseRequest = (
  id: string,
  payload: any
): Promise<BaseApiResponse<{}>> => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_DEVELOPER_BASE_URL"]}/developer-property-transaction/create-purchase-request/${id}`,
      {
        headers: {
          Authorization: `Bearer ${_token}`,
        },
        method: "POST",
        body: payload,
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data: BaseApiResponse<{}>) => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const completedInspection = (id: string, signal: AbortSignal) =>
  apiRequest<any>(
    {
      method: "POST",
      path: `/developer-property-transaction/complete-inspection/${id}`,
      requiresAuth: true,
    },
    signal
  );
export const bookedInspection = (id: string, signal: AbortSignal) =>
  apiRequest<any>(
    {
      method: "POST",
      path: `/developer-property-transaction/book-inspection/${id}`,
      requiresAuth: true,
    },
    signal
  );
export const getNotifications = (
  type: string,
  id: string,
  signal: AbortSignal
) =>
  apiRequest<any>(
    {
      method: "GET",
      path: `/notifications/${type}/${id}`,
      requiresAuth: true,
    },
    signal
  );

export const createForum = (payload: any): Promise<BaseApiResponse<{}>> => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_DEVELOPER_BASE_URL"]}/threads/create-thread`,
      {
        headers: {
          Authorization: `Bearer ${_token}`,
        },
        method: "POST",
        body: payload,
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data: BaseApiResponse<{}>) => {
        if (data) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getAllForum = (signal: AbortSignal) =>
  apiRequest<any>(
    {
      method: "GET",
      path: `/threads/all-threads`,
      requiresAuth: true,
    },
    signal
  );
export const createPostinForum = (
  id: string,
  signal: AbortSignal,
  payload: any
) =>
  apiRequest<any>(
    {
      method: "POST",
      path: `/threads/thread/${id}/posts`,
      requiresAuth: true,
      body: payload,
    },
    signal
  );
export const getAllPostInForum = (id: string, signal: AbortSignal) =>
  apiRequest<any>(
    {
      method: "GET",
      path: `/threads/thread/${id}/posts`,
      requiresAuth: true,
    },
    signal
  );
export const getAgentRequest = (signal: AbortSignal) =>
  apiRequest<any>(
    {
      method: "GET",
      path: `/developer-property-transaction/agent/recent-requests`,
      requiresAuth: true,
    },
    signal
  );

export const updateDocStatus = (
  interestId: string,
  status: string,
  payload: any
) =>
  apiRequest<any>({
    method: "PUT",
    path: `/developer-property-transaction/update-documentation-status/${interestId}?action=${status}`,
    body: payload,
  });
export const AgentRequestAction = (
  action: string,
  id: string,
  signal: AbortSignal
) =>
  apiRequest<any>(
    {
      method: "POST",
      path: `/developer-property-transaction/agent/${action}-interest/${id}`,
      requiresAuth: true,
    },
    signal
  );

export const ConfirmPayment = (
  id: string,
  // payload: { status: boolean; amountPaid: string },
  signal: AbortSignal
) =>
  apiRequest<any>(
    {
      method: "POST",
      path: `/developer-property-transaction/developer/verify-payment/${id}`,
      requiresAuth: true,
      // body: payload,
    },
    signal
  );
export const sendComment = (id: string, payload: any, signal: AbortSignal) =>
  apiRequest<any>(
    {
      method: "POST",
      path: `/developer-property-transaction/developer-user-interest/send-message/${id}`,
      requiresAuth: true,
      body: payload,
    },
    signal
  );
export const getComments = (id: string, signal: AbortSignal) =>
  apiRequest<any>(
    {
      method: "GET",
      path: `/developer-property-transaction/developer-user-interest/send-message/${id}`,
      requiresAuth: true,
    },
    signal
  );
export const fetchDevClientDocument = (id: string, signal: AbortSignal) =>
  apiRequest<any>(
    {
      method: "GET",
      path: `/developer-property-transaction/user-interest/${id}`,
    },
    signal
  );
export const uploadUserKYCDoc = (
  id: string,
  payload: any
): Promise<BaseApiResponse<{}>> => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_DEVELOPER_BASE_URL"]}/developer-property-transaction/developer-user-interest/send-kyc/${id}`,
      {
        headers: {
          Authorization: `Bearer ${_token}`,
        },
        method: "POST",
        body: payload,
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data: BaseApiResponse<{}>) => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const sendClientsDocs = (
  interestId: string,
  payload: any
): Promise<BaseApiResponse<{}>> => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_DEVELOPER_BASE_URL"]}/developer-property-transaction/developer-user-interest/send-document/${interestId}`,
      {
        headers: {
          Authorization: `Bearer ${_token}`,
        },
        method: "POST",
        body: payload,
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data: BaseApiResponse<{}>) => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
