import React from 'react'
import buildingImg from '../../../assets/images/building.png'
import { PropertyCardProps } from '@utils/constants'
import { numberWithCommas } from '@utils/helpers'
import { useNavigate } from 'react-router-dom'
// import { useNavigate } from 'react-router-dom'

export const DeveloperPropertyCard: React.FC<PropertyCardProps> = ({
  property,
  client = false,
}) => {
  const navigate = useNavigate()

  const handleClick = () => {
    const route = client
      ? '/developer-account-project-property/'
      : '/developer-project-property/'

    navigate(`${route}${property._id}`, {
      state: { property },
    })
  }
  return (
    <div className="col">
      <div
        className=" border-light shadow-sm position-relative single-proj"
        style={{ overflow: 'hidden' }}
        onClick={handleClick}
      >
        <div className="">
          <img
            src={property.coverImage || buildingImg}
            className="w-full"
            alt={'building'}
          />
        </div>

        <div className="card-body py-4 px-4 border rounded-b-15 single-proj-body">
          <h5
            className={`fw-bold w-max rounded-50 px-3 fs-12 mb-3 ${
              property.propertyType === 'flats'
                ? 'previous'
                : property.propertyType === 'bungalow'
                ? 'current'
                : 'future'
            }`}
          >
            <span className="capitalize">{property.propertyType} </span>
          </h5>

          <div className="my-3">
            <h6 className="fw-bolder fs-14 my-2">{property.propertyName}</h6>
            <h6 className="fs-14 my-2">
              <span className="uppercase">{property.currency}</span>&nbsp;
              {numberWithCommas(property.price || '0')}
            </h6>

            <p className="fs-14 my-2">{property?.address}</p>
          </div>
          <div className="flex gap-20 items-center my-2">
            <div>
              <span className="fs-14 fw-bold">
                <i className="fa-light fa-bed-front text-primary"></i>&nbsp;{' '}
                {property.numberOfUnits}&nbsp; total units
              </span>
            </div>

            <div>
              <span className="fs-14 fw-bold">
                <i className="fa-light fa-bath text-primary"></i>&nbsp;{' '}
                {property.numberOfBedrooms} &nbsp; available units
              </span>
            </div>
          </div>
          <div className="flex gap-20 items-center">
            <div>
              <span className="fs-14 fw-bold">
                <i className="fa-light fa-bed-front text-primary"></i>&nbsp;{' '}
                {property.numberOfBathrooms}&nbsp; bedrooms
              </span>
            </div>

            <div>
              <span className="fs-14 fw-bold">
                <i className="fa-light fa-bath text-primary"></i>&nbsp;{' '}
                {property.numberOfBedrooms} &nbsp;bathrooms
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
