import React, { useState, useEffect } from 'react'
import buildingImg from '../../../assets/images/building.png'
import DevelopersPagination from '@shared/navbars/DevelopersNavbar/Pagination'
import { getUserPurchasedInterests } from '@services/krentDeveloper'
import { formatDate, numberWithCommas } from '@utils/helpers'
import { useNavigate } from 'react-router-dom'
// import KycUpload from 'components/UsersDashboard/kycUploadModal'

const ITEMS_PER_PAGE = 50

const PurchasedInterests = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [purchases, setPurchases] = useState([])
  const [totalPages, setTotalPages] = useState(1)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchPurchases = async () => {
      setIsLoading(true)
      setError(null)
      try {
        const abortController = new AbortController()
        const response = await getUserPurchasedInterests(abortController.signal)
        setPurchases(response.purchases)
        setTotalPages(Math.ceil(response.purchases.length / ITEMS_PER_PAGE))
      } catch (err) {
        setError(err as any)
      } finally {
        setIsLoading(false)
      }
    }

    fetchPurchases()
  }, [])

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage)
  }

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE
  const currentPurchases = purchases?.slice(
    startIndex,
    startIndex + ITEMS_PER_PAGE,
  )

  if (isLoading) return <div>Loading...</div>
  if (error) return <div>{error}</div>

  return (
    <div>
      <PurchaseList purchases={currentPurchases} startIndex={startIndex} />
      <DevelopersPagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  )
}

const PurchaseList = ({
  purchases,
  startIndex,
}: {
  purchases: any[]
  startIndex: number
}) => (
  <div className="my-20">
    {purchases.length < 1 ? (
      <div className="text-center">
        <h4>No purchases made</h4>
      </div>
    ) : (
      purchases.map((item, idx) => (
        <PurchaseCard key={idx} item={item} index={startIndex + idx} />
      ))
    )}
  </div>
)

const PurchaseCard = ({ item, index }: { item: any; index: number }) => {
  // const [kycModal, setKycModal] = useState(false)

  const navigate = useNavigate()

  const handleViewDetails = () => {
    navigate(`/purchase-details/${item._id}`, {
      state: { purchases: true, item },
    })
  }
  return (
    <div className="sale-interest-card flex justify-between">
      <div className="flex gap-4">
        <h6>{index + 1}.</h6>
        <div>
          <h6 className="fs-10">Initiation date</h6>
          <p>{formatDate(item.createdAt || '')}</p>
        </div>
        <img
          src={item.coverImages ? item.coverImages[0] : buildingImg}
          className=""
          alt="building"
        />
        <div>
          <p
            className={`fw-bold w-max rounded-50 px-3 fs-12 capitalize ${getBadgeClass(
              item.type,
            )}`}
          >
            {item.property.propertyDeedType}
          </p>
          <p>{item.propertyName}</p>
          <h6>
            {item.property.currency}&nbsp;
            {numberWithCommas(item.property.price)}
          </h6>
          <PropertyDetails
            bedrooms={item.property.numberOfBedrooms}
            bathrooms={item.property.numberOfBathrooms}
          />
        </div>
      </div>
      <div className="text-center">
        <h6 className="fs-12">
          Documentation {item.documentationProgress}%
          <br />
          (in progress)
        </h6>
        <div
          className="progress-bar-wrapper"
          style={{
            backgroundColor: '#f3f3f3',
            borderRadius: '10px',
            height: '10px',
            width: '100%',
            marginTop: '10px',
          }}
        >
          <div
            className="progress-bar"
            style={{
              width: `${item.documentationProgress}%`,
              height: '100%',
              backgroundColor: '#47BBA9',
              borderRadius: '10px',
              transition: 'width 0.5s ease-in-out',
            }}
          />
        </div>
      </div>
      <div>
        <h6 className="fs-12">Closed date</h6>
        <p className="text-center">
          {item.closedDate ? formatDate(item.closedDate || '...') : '...'}
        </p>
        <p></p>
      </div>
      <div>
        <h6 className="fs-12">Agent name</h6>
        <p className="capitalize">
          {item.agent.firstname.toLowerCase()}&nbsp;
          {item.agent.lastname.toLowerCase()}
        </p>
        <p></p>
      </div>
      {/* <div>
        <button
          onClick={(e) => {
            e.preventDefault()
            setKycModal(true)
          }}
          className="bg-primary rounded-10 text-white p-2 h-max border-green fs-10 fw-bold"
        >
          Upload KYC Docs
        </button>
      </div> */}
      <div>
        <button onClick={handleViewDetails} className="bg-white">
          <i className="fa-regular fa-ellipsis text-primary rounded-circle p-1 border-green"></i>
        </button>
      </div>
      {/* <KycUpload
        show={kycModal}
        onClose={() => setKycModal(false)}
        propertyId={item._id}
        property={item}
      ></KycUpload> */}
    </div>
  )
}
const PropertyDetails = ({
  bedrooms,
  bathrooms,
}: {
  bedrooms: number
  bathrooms: number
}) => (
  <div className="flex gap-4">
    <div className="flex gap-2 items-center">
      <i className="fa-regular fa-bed-front text-primary"></i>
      <h6>{bedrooms}</h6>
    </div>
    <div className="flex gap-2 items-center">
      <i className="fa-regular fa-bath text-primary"></i>
      <h6>{bathrooms}</h6>
    </div>
  </div>
)

const getBadgeClass = (type: string) => {
  switch (type) {
    case 'shortlet':
      return 'previous'
    case 'rent':
      return 'current'
    default:
      return 'future'
  }
}

export default PurchasedInterests
