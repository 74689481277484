import { logout } from "@services/krent.service";
import { useSessionStorage } from "../../../hooks";
import Logo from "@shared/Logo";
import React from "react";
import { Navbar, NavDropdown } from "react-bootstrap";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { removeCookie } from "@utils/cookies";

interface NavItemProps {
  to: string | { pathname: string };
  children: React.ReactNode;
  target?: string;
}
interface NavDropdownItemProps {
  to: string;
  children: React.ReactNode;
  setPage: any;
}

interface NavItemProps {
  to: string | { pathname: string };
  children: React.ReactNode;
  target?: string;
}

const NavItem: React.FC<NavItemProps> = ({ to, children, target }) => (
  <li className="nav-item">
    {typeof to === "string" ? (
      <NavLink className="nav-link" to={to}>
        {children}
      </NavLink>
    ) : (
      <a
        className="nav-link"
        href={to.pathname}
        target={target}
        rel={target === "_blank" ? "noreferrer" : undefined}
      >
        {children}
      </a>
    )}
  </li>
);

const NavDropdownItem: React.FC<NavDropdownItemProps> = ({
  to,
  children,
  setPage,
}) => (
  <span className="dropdown-item" onClick={() => setPage(1)}>
    <NavLink className="nav-link" to={to}>
      {children}
    </NavLink>
  </span>
);

export const NavBarMenu = ({ setPage }: any) => {
  const [user, setUser] = useSessionStorage("user", {});
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    let abortController = new AbortController();
    logout(abortController.signal)
      .then(() => {
        setUser({});
        window && window.sessionStorage.clear();
        removeCookie("1Q_SPA");
        navigate("/", { replace: true, state: { from: location } });
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <Navbar bg="white" expand="lg" className="fixed-top">
      <div className="container py-2 py-md-0 d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex flex-row justify-content-between align-items-center col-12 col-lg-auto">
          <Navbar.Brand>
            <Link to="/">
              <Logo />
            </Link>
          </Navbar.Brand>
          <div className="d-flex align-items-center gap-3">
            {!user.id && (
              <Link
                className="btn btn-custom-primary fs-12 d-lg-none fw-600 py-2 px-3 rounded-pill w-md-25 mb-1"
                to="/get-started/"
              >
                Get Started
              </Link>
            )}
            <Link
              className="nav-link fw-bold py-2 d-lg-none text-center fs-14 "
              to="/sign-in/"
            >
              {user.id ? "My Profile" : "Sign In"}
            </Link>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              className="border-0"
            />
          </div>
        </div>
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="justify-content-center fs-14 fw-600"
        >
          <ul className="navbar-nav mr-auto">
            <NavItem to="/">Home</NavItem>

            <li>
              <NavDropdown
                title="For Sale"
                id="basic-nav-dropdown"
                className="custom-dropdown"
              >
                <NavDropdownItem
                  to="/property-for-sale/house"
                  setPage={setPage}
                >
                  House for sale
                </NavDropdownItem>
                <NavDropdownItem to="/property-for-sale/land" setPage={setPage}>
                  Land for sale
                </NavDropdownItem>
                <NavDropdownItem
                  to="/property-for-sale/apartment"
                  setPage={setPage}
                >
                  Flats & Apartments for Sale
                </NavDropdownItem>
                <NavDropdownItem
                  to="/property-for-sale/commercial"
                  setPage={setPage}
                >
                  Commercial Property for Sale
                </NavDropdownItem>
                <NavDropdownItem to="/property-for-sale" setPage={setPage}>
                  All Property for sale
                </NavDropdownItem>
              </NavDropdown>
            </li>
            <li>
              <NavDropdown
                title="For Rent"
                id="basic-nav-dropdown"
                className="custom-dropdown"
              >
                <NavDropdownItem
                  to="/property-for-rent/house"
                  setPage={setPage}
                >
                  House for rent
                </NavDropdownItem>
                <NavDropdownItem
                  to="/property-for-rent/apartment"
                  setPage={setPage}
                >
                  Flats & Apartments for Rent
                </NavDropdownItem>
                <NavDropdownItem to="/property-for-rent/land" setPage={setPage}>
                  Lands Lease
                </NavDropdownItem>
                <NavDropdownItem to="/property-for-rent" setPage={setPage}>
                  All Property for rent
                </NavDropdownItem>
              </NavDropdown>
            </li>
            <li>
              <NavDropdown
                title="For Shortlet"
                id="basic-nav-dropdown"
                className="custom-dropdown"
              >
                <NavDropdownItem
                  to="/property-for-shortlet/in/lekki"
                  setPage={setPage}
                >
                  Short Let in Lekki
                </NavDropdownItem>
                <NavDropdownItem
                  to="/property-for-shortlet/in/lagos"
                  setPage={setPage}
                >
                  Short Let in Lagos
                </NavDropdownItem>
                <NavDropdownItem
                  to="/property-for-shortlet/in/abuja"
                  setPage={setPage}
                >
                  Short Let in Abuja
                </NavDropdownItem>
                <NavDropdownItem to="/property-for-shortlet" setPage={setPage}>
                  All Shortlet Property
                </NavDropdownItem>
              </NavDropdown>
            </li>
            <NavItem to="/explore">Explore</NavItem>
            <NavItem
              to={{ pathname: "https://blog.krent.space/area-guide" }}
              target="_blank"
            >
              Area Guide
            </NavItem>
            <NavItem
              to={{ pathname: "https://blog.krent.space/" }}
              target="_blank"
            >
              Krent Blog
            </NavItem>
            {/* <NavItem to={{ pathname: '/developer-account' }} >
              Developer Listings
            </NavItem> */}

            <li className="nav-item">
              {!user.id ? (
                <NavLink className="nav-link d-lg-none" to="/sign-in/">
                  Sign In
                </NavLink>
              ) : (
                <button
                  className="nav-link text-red d-lg-none bg-white"
                  onClick={handleLogout}
                >
                  Sign Out
                </button>
              )}

              <a
                className="d-lg-none d-flex me-auto bg-success rounded-circle align-items-center justify-content-center social-icon2 mb-2"
                href="https://wa.me/+2349118008008"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-whatsapp fs-22 text-white"></i>
              </a>
            </li>
          </ul>
        </Navbar.Collapse>
        <div className="navbar-nav d-flex justify-content-center align-items-center">
          {!user.id && (
            <Link
              className="btn btn-custom-primary d-none d-lg-inline-block fs-12 fw-600 py-2 px-4 rounded-pill"
              to="/get-started/"
            >
              Get Started
            </Link>
          )}
          <Link
            className="nav-link fw-bold py-2 d-none text-center fs-14 d-lg-inline-block"
            to="/sign-in/"
          >
            {user.id ? "My Profile" : "Sign In"}
          </Link>
          <a
            className="d-none d-lg-flex me-auto bg-success rounded-circle align-items-center justify-content-center social-icon2 ml-2"
            href="https://wa.me/+2349118008008"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-brands fa-whatsapp fs-22 text-white"></i>
          </a>
        </div>
      </div>
    </Navbar>
  );
};
