import React, { useState, ChangeEvent, FormEvent } from "react";
import { toast } from "react-toastify";
import { addReferences } from "@services/krent.service";

interface ReferenceFormData {
  fullName1: string;
  email1: string;
  phone1: string;
  fullName2: string;
  email2: string;
  phone2: string;
}

interface ReferenceFormProps {
  transactionId: string;
  setOpenReferenceForm: any;
  setTransaction?: any;
}

interface ReferenceSectionProps {
  number: 1 | 2;
  formData: ReferenceFormData;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  title?: string;
  subtitle?: string;
}

const initialFormData: ReferenceFormData = {
  fullName1: "",
  email1: "",
  phone1: "",
  fullName2: "",
  email2: "",
  phone2: "",
};

const ReferenceSection: React.FC<ReferenceSectionProps> = ({
  title,
  subtitle,
  formData,
  number,
  handleChange,
}) => (
  <div className="my-4">
    <h6>{title}</h6>
    <p>{subtitle}</p>
    <div className="my-3">
      <label htmlFor={`fullName${number}`} className="form-label fs-16 fw-bold">
        Full name of reference
      </label>
      <input
        type="text"
        className="form-control rounded-50 bg-white"
        id={`fullName${number}`}
        name={`fullName${number}`}
        value={formData[`fullName${number}` as keyof ReferenceFormData]}
        onChange={handleChange}
        required
      />
    </div>

    <div className="my-3">
      <label htmlFor={`email${number}`} className="form-label fs-16 fw-bold">
        Email of reference
      </label>
      <input
        type="email"
        className="form-control rounded-50 bg-white"
        id={`email${number}`}
        name={`email${number}`}
        value={formData[`email${number}` as keyof ReferenceFormData]}
        onChange={handleChange}
        required
      />
    </div>

    <div className="my-3">
      <label htmlFor={`phone${number}`} className="form-label fs-16 fw-bold">
        Phone number
      </label>
      <input
        type="tel"
        className="form-control rounded-50 bg-white"
        id={`phone${number}`}
        name={`phone${number}`}
        value={formData[`phone${number}` as keyof ReferenceFormData]}
        onChange={handleChange}
        required
      />
    </div>
  </div>
);

const ReferenceForm: React.FC<ReferenceFormProps> = ({
  transactionId,
  setOpenReferenceForm,
  setTransaction,
}) => {
  const [formData, setFormData] = useState<ReferenceFormData>(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const isFormValid = (): boolean => {
    return Object.values(formData).every((value) => value.trim() !== "");
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setError(null);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    if (!isFormValid()) return;

    setIsSubmitting(true);
    setError(null);

    try {
      const response = await addReferences(transactionId, formData);
      toast.success("References submitted successfully");
      setOpenReferenceForm(false);
      setFormData(initialFormData);
      // Update transaction with the response data
      setTransaction((response as any).data);
    } catch (err) {
      const errorMessage =
        err instanceof Error ? err.message : "Failed to submit references";
      setError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <h5>Reference details</h5>
      <ReferenceSection
        number={1}
        title="CLOSE RELATION"
        subtitle="Note; This could be your close friend, family member, or any close relation"
        formData={formData}
        handleChange={handleChange}
      />
      <ReferenceSection
        number={2}
        formData={formData}
        title="FINANCIAL GUARANTOR"
        subtitle="Note; This could be your employer,  business partner, and financial advisor"
        handleChange={handleChange}
      />

      {error && (
        <div className="text-red-500 mt-2 mb-2 text-center">{error}</div>
      )}

      <div className="flex gap-2 mt-4">
        <button
          type="submit"
          className="bg-primary rounded-50 w-full text-white p-2 fs-16"
          disabled={!isFormValid() || isSubmitting}
        >
          {isSubmitting ? "Submitting..." : "Submit references"}
        </button>
      </div>
    </form>
  );
};

export default ReferenceForm;
