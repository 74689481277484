import React, { useState, useEffect, useCallback } from 'react'
import {
  Autocomplete,
  DirectionsRenderer,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api'
import CustomSelect from '@shared/forms/select'

export interface PropertyLocationState {
  address: string
  city: string
  state: string
  country: string
  neighbourhood: string
  apartmentNumber: string
  floodRisk: string
}
const riskOptions = [
  { value: 'low', label: 'Low' },
  { value: 'high', label: 'High' },
  { value: 'medium', label: 'Medium' },
]

interface PropertyLocationProps {
  locationDetails: PropertyLocationState
  onLocationDetailsChange: (newDetails: Partial<PropertyLocationState>) => void
}

const PropertyLocation: React.FC<PropertyLocationProps> = ({
  locationDetails,
  onLocationDetailsChange,
}) => {
  const [center, setCenter] = useState({ lat: 9.082, lng: 8.6753 })
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [directionsResponse] = useState<google.maps.DirectionsResult | null>(
    null,
  )
  const [autoComplete, setAutoComplete] = useState<any>(null)

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env['REACT_APP_GOOGLE_API_KEY'] || '',
    libraries: ['places'],
  })

  const handleChange = (field: keyof PropertyLocationState) => (
    e: React.ChangeEvent<HTMLInputElement> | string,
  ) => {
    if (typeof e === 'string') {
      onLocationDetailsChange({ [field]: e })
    } else {
      onLocationDetailsChange({ [field]: e.target.value })
    }
  }

  const updateMapCenter = useCallback(async () => {
    if (!isLoaded) return

    const geocoder = new google.maps.Geocoder()
    const address = `${locationDetails.address}, ${locationDetails.city}, ${locationDetails.state}, ${locationDetails.country}`

    try {
      const result = await geocoder.geocode({ address })
      if (result.results[0]) {
        const { lat, lng } = result.results[0].geometry.location
        const newCenter = { lat: lat(), lng: lng() }
        setCenter(newCenter)
        map?.panTo(newCenter)
        map?.setZoom(15)
      }
    } catch (error) {
      console.error('Error geocoding address:', error)
    }
  }, [locationDetails, map, isLoaded])

  useEffect(() => {
    updateMapCenter()
  }, [
    locationDetails.address,
    locationDetails.city,
    locationDetails.state,
    locationDetails.country,
    updateMapCenter,
  ])

  const onLoad = (autocomplete: any) => {
    setAutoComplete(autocomplete)
  }
  const onPlaceChanged = () => {
    if (autoComplete !== null) {
      const place = autoComplete.getPlace()
      const newLat = place?.geometry?.location?.lat()
      const newLng = place?.geometry?.location?.lng()
      const newAddress = place?.formatted_address

      if (newLat && newLng && newAddress) {
        setCenter({ lat: newLat, lng: newLng })
        onLocationDetailsChange({ address: newAddress })
      }
    } else {
      console.log('Autocomplete is not loaded yet!')
    }
  }

  const renderInputField = (
    label: string,
    field: keyof PropertyLocationState,
    placeholder: string,
  ) => (
    <div>
      <label className="fw-bolder form-label text-capitalize">{label}</label>
      {field === 'address' ? (
        <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
          <input
            type="text"
            className="form-control rounded-pill py-3 px-4 fs-14 mb-3"
            value={locationDetails[field]}
            onChange={handleChange(field)}
            placeholder={placeholder}
          />
        </Autocomplete>
      ) : (
        <input
          type="text"
          className="form-control rounded-pill py-3 px-4 fs-14 mb-3"
          value={locationDetails[field]}
          onChange={handleChange(field)}
          placeholder={placeholder}
        />
      )}
    </div>
  )

  if (!isLoaded) return <div>Loading...</div>

  return (
    <div>
      <div className="my-3">
        <h6 className="my-3">Location Details</h6>
        <div style={{ height: '25rem', position: 'relative' }}>
          <GoogleMap
            center={center}
            zoom={15}
            mapContainerStyle={{ width: '100%', height: '100%' }}
            onLoad={setMap}
          >
            <Marker position={center} />
            {directionsResponse && (
              <DirectionsRenderer directions={directionsResponse} />
            )}
          </GoogleMap>

          <div
            className="me-auto bg-white rounded-circle d-flex align-items-center justify-content-center social-icon mb-2 cursor-pointer"
            style={{ position: 'absolute', left: '30px', bottom: '30px' }}
            onClick={() => {
              map?.panTo(center)
              map?.setZoom(15)
            }}
          >
            <i className="fa fa-location-arrow fs-22 text-primary"></i>
          </div>
        </div>
        <p className="choose-file my-3 p-3">
          For address accuracy and consistency please select the Google
          suggestion as you type on all fields
        </p>
      </div>
      <div className="my-4">
        {renderInputField('Enter Address', 'address', 'Enter accurate address')}
      </div>

      <div className="grid-4 my-2">
        {renderInputField('City', 'city', 'Select city')}
        {renderInputField('State', 'state', 'Select state')}
        {renderInputField('Country', 'country', 'Select country')}
        {renderInputField(
          'Neighbourhood',
          'neighbourhood',
          'Select neighborhood',
        )}
        {renderInputField(
          'Apartment Number',
          'apartmentNumber',
          'Enter number',
        )}
        <CustomSelect
          label="Flood risk"
          value={locationDetails.floodRisk}
          onChange={(value) => handleChange('floodRisk')(value)}
          options={riskOptions}
          className='my-0'
        />
        {/* {renderInputField('Flood risk', 'floodRisk', 'Select Flood risk')}  */}
      </div>
    </div>
  )
}

export default PropertyLocation
