import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import { BankTransfer } from "../BankTransfer";
import { Transaction } from "@interfaces/transaction";
type Data = {
  transaction: Transaction;
  user?: any;
  setOpenSummary: any;
  payNow?: any;
  payLoading?: any;
  kyc?: any;
};

export const CustomerAccepted = ({
  transaction,
  user,
  setOpenSummary,
  // payNow,
  // payLoading,
  kyc,
}: Data): any => {
  return (
    <Fragment>
      {transaction?.renter?.id === user?.id ? (
        <Fragment>
          <div className="mb-3">
            <i className="iconly-Paper align-text-bottom icli fs-22 me-1"></i>

            <span
              className="fs-14 text-link"
              onClick={() => setOpenSummary(true)}
            >
              Summary
            </span>
          </div>
          {/* bill[0] */}
          <div className="alert alert-warning" role="alert">
            <p className="fs-14">Please proceed to make payment</p>
            <div className="d-flex justify-content-between mb-2">
              <span className="fs-14 fw-bold">Property Price:</span>
              <span className="fs-14">
                {transaction?.bill[0]?.propertyPrice?.toLocaleString()}
              </span>
            </div>
            
            <div className="d-flex justify-content-between mb-2">
              <span className="fs-14 fw-bold">Legal Fee:</span>
              <span className="fs-14">
                {transaction?.bill[0]?.legalFee
                  ? transaction?.bill[0]?.legalFee?.toLocaleString()
                  : "0 (100% Discount)"}
              </span>
            </div>
            
            {transaction?.bill[0]?.insuranceFee && (
              <div className="d-flex justify-content-between mb-2">
                <span className="fs-14 fw-bold">Insurance Fee:</span>
                <span className="fs-14">
                  {transaction?.bill[0]?.insuranceFee?.toLocaleString()}
                </span>
              </div>
            )}
            {transaction?.bill[0]?.cautionFee && (
              <div className="d-flex justify-content-between mb-2">
                <span className="fs-14 fw-bold">Caution Fee:</span>
                <span className="fs-14">
                  {transaction?.bill[0]?.cautionFee?.toLocaleString()}
                </span>
              </div>
            )}
            {/* <div className='d-flex justify-content-between mb-2'>
              <span className='fs-14 fw-bold'>Insurance Fee (12 months):</span>
              <span className='fs-14'>
                {transaction?.bill[0]?.cautionFee?.toLocaleString() || (transaction?.listing as any)?.cautionFee?.toLocaleString()}
              </span>
            </div> */}
            <div className="d-flex justify-content-between mb-2">
              <span className="fs-14 fw-bold">Krent Admin Charges:</span>
              <span className="fs-14">
                {transaction?.bill[0]?.krentAdminCharge?.toLocaleString()}
              </span>
            </div>
            {transaction?.bill[0]?.tax && (
              <div className="d-flex justify-content-between mb-4">
                <span className="fs-14 fw-bold">Tax:</span>
                <span className="fs-14">
                  {transaction?.bill[0]?.tax?.toLocaleString()}
                </span>
              </div>
            )}
            <div className="d-flex justify-content-between mb-2">
              <span className="fs-14 fw-bold">Total:</span>
              <span className="fs-14">
                {transaction?.bill[0]?.total?.toLocaleString()}
              </span>
            </div>
          </div>
          {/* kyc check */}
          <div className="fs-10 text-danger text-center  ">
            {kyc?.status === "PENDING" ? (
              <span>
                Please reach out to Krent support on{" "}
                <span className="text-primary">+234 911 800 8008 </span> for an
                update on your KYC pending approval to enable you proceed with
                the payment.
              </span>
            ) : kyc?.status === "APPROVED" ? (
              ""
            ) : (
              <span>
                {" "}
                Please complete your kyc <Link to="/settings">here</Link> to
                proceed
              </span>
            )}
          </div>
          {/* pay button */}
          {transaction?.listing?.intent === "sale" ? (
            <button className="btn btn-primary w-100">
              Contact krent for payment
            </button>
          ) : (
            <div className="">
              <BankTransfer transaction={transaction} />

              {/* <button
                className='btn btn-primary w-100 mt-2'
                onClick={payNow}
                // disabled={kyc.status !== 'APPROVED'}
              >
                {payLoading ? (
                  <span
                    className='spinner-border spinner-border-sm'
                    role='status'
                    aria-hidden='true'
                  ></span>
                ) : (
                  'Pay with Paystack'
                )}
              </button> */}
              {/* {transaction?.listing?.intent === 'long lease' && (
                <div className=' ml-3'>
                  <i className='fa-solid fa-lock fs-8'></i>
                  <span className='fs-8 ml-1'>
                    Payment Secured by{' '}
                    <strong className='text-primary'>Paystack</strong>{' '}
                  </span>
                </div>
              )} */}
            </div>
          )}
        </Fragment>
      ) : (
        <div className="alert alert-primary" role="alert">
          <p className="fs-14">
            Krent is currently awaiting payment from customer.
          </p>
        </div>
      )}
    </Fragment>
  );
};
