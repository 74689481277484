import React from "react";

import ScrollToTop from "@utils/scrollToTop";
import GetMeta from "@pages/SeoPages/GetMeta";
import { NavBarMenu } from "@shared/navbars/NavBarMenu";
import { BaseFooter } from "@shared/navbars";
// import Marquee from 'react-fast-marquee';

type Props = {
  news?: any;
  children?: JSX.Element | JSX.Element[];
  handleSearchTab?: any;
  setPage?: any;
};

export const BaseLayout: React.FC<Props> = ({
  // news,
  children,
  setPage,
}: // handleSearchTab,
Props) => {
  <GetMeta description="Krent is a real estate and property listing website in Nigeria. Find homes to buy or rent from trusted landlords and agents. Sell your property or find a new home easily with our wide selection of flats, apartments, houses, and more" />;
  return (
    <React.Fragment>
      {/* <BaseNavBar showBanner={news ? true : false} news={news} /> */}
      <NavBarMenu setPage={setPage} />
      {/* <BaseNavBar2
        showBanner={news ? true : false}
        news={news}
        handleSearchTab={handleSearchTab}
      /> */}
      {/* {children} */}
      <div style={{ marginTop: "5rem" }}>{children}</div>
      <ScrollToTop />
      <BaseFooter setPage={setPage} />
    </React.Fragment>
  );
};
