import {PrimaryButton} from '@shared/buttons';
import React from 'react';

export function TransactionInspected(
  setOpenTerms: React.Dispatch<React.SetStateAction<boolean>>,
  setOpenPolicy: React.Dispatch<React.SetStateAction<boolean>>,
  agree: boolean,
  setAgree: React.Dispatch<React.SetStateAction<boolean>>,
  acceptLoading: boolean,
  acceptBooking: () => void,
  cancelBooking?: any
): React.ReactNode {
  return (
    <>
      <div className='col my-2 '>
        <span
          className='fs-12 text-link mr-3'
          onClick={() => setOpenTerms(true)}
        >
          Terms and Conditions
        </span>
        <span className='fs-12 text-link' onClick={() => setOpenPolicy(true)}>
          Renting Policy
        </span>
        <div className='form-check'>
          <input
            className='form-check-input'
            type='checkbox'
            name='terms'
            checked={agree}
            id='terms'
            onChange={() => setAgree(!agree)}
          />
          <label className='form-check-label fs-10 fw-400' htmlFor='terms'>
            By accepting you agree to Krent's terms and condition.
          </label>
        </div>
      </div>
      <div className='d-grid gap-2'>
        <PrimaryButton
          loading={acceptLoading}
          click={acceptBooking}
          type='primary'
          text='Accept'
          disable={!agree}
        />
        <PrimaryButton type='outline' text='Reject' 
          click={cancelBooking}
        
        />
      </div>
    </>
  );
}
