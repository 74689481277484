import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import AmenitiesChecklist from "./amenitiesChecklist";

const ValidateAmenitiesModal = ({ transaction, setTransaction }: any) => {
  const [openValidateAmenities, setOpenValidateAmenities] = useState(true);
  const [whyValidating, setWhyValidating] = useState(false);

  return (
    <div>
      <div>
        <p>
          Inspecting the property? Please click the button only if you're
          currently inspecting the property to open a checklist of items in the
          property and validate if found in the property
        </p>
        <div className="justify-content-center d-flex">
          <button
            onClick={() => setOpenValidateAmenities(true)}
            className="bg-primary rounded-50 p-2 w-max mx-auto text-white mt-2 items-center"
          >
            Validate amenities
          </button>
        </div>
        <p
          className="mt-4 text-primary cursor-pointer"
          onClick={() => setWhyValidating(true)}
        >
          <i className="fa-regular fa-circle-info"></i>
          &nbsp; Why am i doing this?
        </p>
      </div>

      <Modal
        show={openValidateAmenities}
        onClose={() => setOpenValidateAmenities(false)}
        dismissible={true}
        size="lg"
      >
        <div className="container p-4">
          <div className="d-flex justify-content-between ">
            <h5 className="fw-bold">Amenities checklist</h5>
            <button
              className="bg-white"
              onClick={() => setOpenValidateAmenities(false)}
            >
              <i className="fa-regular fa-x"></i>
            </button>
          </div>
          <p className="fs-16 mt-2">
            These amenities are of great importance in reconcillation to the
            property. Please accurately list all amenities as they exist in the
            property, as they will need to be reconfirmed
          </p>
          <AmenitiesChecklist
            transactionId={transaction.id}
            amenities={transaction.listing?.amenities}
            setOpenValidateAmenities={setOpenValidateAmenities}
            closeModal={() => setOpenValidateAmenities(false)}
            setTransaction={setTransaction}
          />
        </div>
      </Modal>
      <Modal
        show={whyValidating}
        onClose={() => setWhyValidating(false)}
        dismissible={true}
      >
        <div className="container p-4">
          <div className="d-flex justify-content-between text-primary ">
            <p className="text-primary fw-bold">
              <i className="fa-regular fa-circle-info"></i>&nbsp; Why am i doing
              this?
            </p>
            <button
              className="bg-white"
              onClick={() => setWhyValidating(false)}
            >
              <i className="fa-regular fa-x"></i>
            </button>
          </div>
          <div className="mt-4">
            <h6 className="fs-14">
              Your validation confirms the availability and functionality of
              listed amenities, ensuring:
            </h6>
            <p>- Your expectations are met</p>
            <p>- Smooth rental experience</p>
            <p>- No last-minute surprises</p>
          </div>
          <div className="mt-4">
            <h6>It also ensures:</h6>
            <p>- Accurate information about the property </p>
            <p>- Enhanced user experience </p>
            <p>- Increased trust and credibility</p>
            <p>- Reduced disputes and liability</p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ValidateAmenitiesModal;
