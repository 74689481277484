import { useRef, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
// import Autocomplete from 'react-google-autocomplete';
import { PropertyKind } from "@interfaces/properties.interface";
// import {formatCurrency} from '@utils/currency';
// import {useJsApiLoader,
//   Autocomplete
//  } from '@react-google-maps/api';
// import GlobalSearch from 'components/GlobalSearch';
import { searchPropByKey } from "@services/krent.service";
import { addPageToSessionStorage } from "@utils/helpers";

type Props = {
  location?: string;
  propertySize?: string;
  price?: string;
  propertyTypes?: PropertyKind[];
  status?: string;
  intent?: string | null;
  maxPprice?: any;
  extraClass?: string;
  setPropertyIntent?: any;
  sizeClass: string;
  page?: number;
  setPage?: (param: number) => void;
};

export const SearchCard2: React.FC<Props> = ({
  // intent,
  // maxPprice,
  // propertyTypes = [],
  // status,
  sizeClass,
  extraClass,
  setPage,
}: // setPropertyIntent,
Props) => {
  const navigate = useNavigate();

  // const {isLoaded} = useJsApiLoader({
  //   googleMapsApiKey: process.env['REACT_APP_GOOGLE_API_KEY'] || '',
  //   libraries: ['places'],
  // });

  // const [propertyLocation, setPropertyLocation] = useState<string>('');
  // const [autoCompleteLocation, setAutoCompleteLocation] = useState<string>('');

  const [keyWord, setKeyWord] = useState<string>("");
  const [keyWordLoading, setKeyWordLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const prevKeyWord = useRef<string>("");
  // const [propertyLocation, setPropertyLocation] =
  //   useState<google.maps.places.PlaceResult>();

  // const caps = (string: any) => {
  //   return string.charAt(0).toUpperCase() + string.slice(1);
  // };

  // const [autoCompleteState, setAutoCompleteState] = useState<any>(null);
  // const onLoadState = (autocomplete: any) => {
  //   setAutoCompleteState(autocomplete);
  // };
  // const onStateChanged = () => {
  //   if (autoCompleteState !== null) {
  //     const state =
  //       autoCompleteState
  //         .getPlace()
  //         .address_components?.find((p: any) =>
  //           p.types.includes('sublocality_level_1')
  //         )?.long_name ||
  //       autoCompleteState
  //         .getPlace()
  //         .address_components?.find((p: any) =>
  //           p.types.includes('neighborhood')
  //         )?.long_name ||
  //       autoCompleteState
  //         .getPlace()
  //         .address_components?.find((p: any) => p.types.includes('locality'))
  //         ?.long_name ||
  //       autoCompleteState
  //         .getPlace()
  //         .address_components?.find((p: any) =>
  //           p.types.includes('administrative_area_level_1')
  //         )?.long_name;

  //     setAutoCompleteLocation(state);

  //     // console.log(autoCompleteState.getPlace().address_components);
  //   } else {
  //     console.log('Autocomplete is not loaded yet!');
  //   }
  // };

  const onSearchKeyWord = async () => {
    if (!keyWord) {
      navigate("/properties/");
      return;
    }
    setKeyWordLoading(true);
    try {
      const result = await searchPropByKey(keyWord);

      if (result?.data?.length > 0) {
        setKeyWord("");
        setError("");
        // reset page back to 1 on every new search
        if (setPage) {
          setPage(1);
        }
        addPageToSessionStorage(1);
        navigate("/properties/", { state: { searchResult: result, keyWord } });
        setKeyWordLoading(false);
      } else {
        setError("Your search returned no matches.");
        setKeyWordLoading(false);
        // setTimeout(() => {
        //   setError('');
        // }, 4000);
      }
    } catch (error) {
      console.log("Error during search:", error);
      setError(
        "An error occurred while processing your request. Please try again later."
      );
      setKeyWordLoading(true);
    }
  };

  const handleFilter = () => {
    navigate("/properties/");
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyWord(e.target.value);
    setError(""); // Clear error when input changes
  };

  const handleInputClear = () => {
    if (window.location.pathname !== "/")
      navigate(
        window.location.pathname.includes("properties")
          ? "/explore/"
          : "/properties/"
      );
  };

  // const onSearch = (): void => {
  //   let params: URLSearchParams = new URLSearchParams();
  //   if (intent) params.append("intent", intent);

  //   navigate(
  //     {
  //       pathname: "/properties/",
  //       search: `?${createSearchParams(params)}`,
  //     },
  //     { replace: false }
  //   );
  // };
  // useEffect(() => {
  //   if (page !== 1) {
  //     onSearchKeyWord();
  //   }
  // }, [page]);

  return (
    <>
      <div className="container-flex">
        <div
          className={`d-flex border-shadow bg-white border-round pl-3  py-0 py-md-2 px-0 px-md-3 m-0  gap-md-3 align-items-center ${extraClass}`}
        >
          <div className={` ${sizeClass}`}>
            <div className="row py-3 pl-3 px-md-3 m-0">
              {/* <div className='col-6 col-lg-3 fs-16'> */}
              <div className="col-12 fs-16  px-0">
                <div className="row align-items-center  justify-content-center  ">
                  <div className=" px-0 ">
                    {error && <p className="text-danger ">{error}</p>}
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        onSearchKeyWord();
                      }}
                    >
                      <div className="input-group input-group-flush input-group-merge border rounded">
                        <div className="input-group-prepend ">
                          <span className="input-group-text  btn px-2 py-2  left-0 ">
                            <i className="fa fa-search"></i>
                          </span>
                        </div>
                        <input
                          className="form-control list-search  border-0 bg-white search-inp px-0 pl-2"
                          type="text"
                          name="searchKey"
                          value={keyWord}
                          onChange={handleInputChange}
                          onInput={(e: any) => {
                            const currentKeyWord = e.target.value;
                            if (prevKeyWord.current && currentKeyWord === "") {
                              handleInputClear();
                            }
                            prevKeyWord.current = currentKeyWord;
                          }}
                          placeholder="Search title, ref, city etc..."
                        />
                        {window.location.pathname !== "/" && keyWord && (
                          <Link
                            className=" btn-link  px-2 text-danger ml-1 d-flex pt-1  text-decoration-none rounded-0 input-group-text  bg-white border-0"
                            to={
                              window.location.pathname.includes("properties")
                                ? "/explore"
                                : "/properties"
                            }
                            onClick={() => setKeyWord("")}
                          >
                            <i className="fa fa-x border border-danger fs-8 p-1  rounded-pill"></i>
                          </Link>
                        )}
                        {/* <button type='submit' className='btn btn-primary'>
              search
            </button> */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-none d-md-flex col-md-6  gap-2 p-md-3">
            {/* <div className='row d-flex  '> */}
            <button
              className="  btn-outline-search"
              // className='btn btn-link fw-bold px-0 no-decoration'
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasExample"
              aria-controls="offcanvasExample"
              onClick={handleFilter}
            >
              <i className="fas fa-sliders"></i> Filter
            </button>
            <button
              className="btn  btn-search rounded-pill"
              onClick={() => {
                addPageToSessionStorage(1);
                onSearchKeyWord();
              }}
            >
              {keyWordLoading ? "Loading" : "Search"}
            </button>

            {/* <PrimaryButton
							type='primary'
							text='Search'
							// width={window.innerWidth >= 512 ? '80%' : '100%'}
						/> */}
            {/* </div> */}
          </div>
          <div
            className="col-4 flex-grow-1 ml-2  d-md-none px-3 gap-2 d-flex align-items-center justify-content-center"
            style={{ height: "24px", width: "24px" }}
          >
            <button
              className=" border-0 btn-outline-search p-0"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasExample"
              aria-controls="offcanvasExample"
              onClick={handleFilter}
            >
              <i className="fas fa-sliders fs-20"></i>
            </button>
            <button
              className="btn bg-primary  text-white rounded-pill d-flex  align-items-center justify-content-center"
              onClick={() => {
                addPageToSessionStorage(1);
                onSearchKeyWord();
              }}
              style={{ height: "28px", width: "28px" }}
            >
              <i className="fas fa-search fs-16"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
